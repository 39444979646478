import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Link,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import { setFrames, userActions } from '../../../actions/user.actions';
import BikeOwnerInfo from '../../../components/organisms/bikeDetailOwnerSection/bikeOwnerInfo';
import AddFrameModal from '../../../components/organisms/addFrameModal/addFrameModal';
import { OrderFEType, ReduxStateType } from '../../../store/initialState';
import { BikeType } from '../../../types/bike.types';
import { bikeActions } from '../../../actions/bike.actions';
import OrderService from '../../../services/orders.service';
import ConnectFrameModal from '../../../components/organisms/connectFrameAndSKUModal/connectFrameModal';
import FrameService from '../../../services/frame.service';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useStyles } from './userDetailPage.styles';
import OrderTable from './orderTable/orderTable';

export const UserDetailPage = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const dispatch = useDispatch();
  const bikes = useSelector((state: ReduxStateType) => state.userInfo?.Frames);
  const currentBike = useSelector((state: ReduxStateType) => state.bike.bike);
  const [expandedBike, setExpandedBike] = useState<number[] | null>(null);
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const [orders, setOrders] = useState(user?.Orders);
  const [activeOrders, setActiveOrders] = useState<OrderFEType[]>([]);

  useEffect(() => {
    if (user) {
      if (user.ServiceGroup !== undefined && user.ServiceGroup.length > 0) {
        history.push('/serviceDashboard');
      } else {
        OrderService.getUserOrders(
          user?._id,
          (orders) => {
            dispatch(userActions.setOrders(orders));
          },
          (errorMessage) => displaySnackbar('error', errorMessage),
        );
        if (bikes === undefined) {
          FrameService.getBikes(
            user.Email,
            (response) => {
              dispatch(setFrames(response));
            },
            (error) => {
              displaySnackbar('error', error);
            },
          );
        }
      }
    }
  }, []);

  useEffect(() => {
    if (user?.Orders) {
      setOrders([...user.Orders]);
      const notCanceledOrders = [...user.Orders]?.filter(
        (order: OrderFEType) => order.OrderStateCanceled === null,
      );
      if (notCanceledOrders !== undefined) {
        setActiveOrders([...notCanceledOrders]);
      }
    }
  }, [user?.Orders]);

  useEffect(() => {
    if (currentBike && bikes) {
      const searchedBike: BikeType | undefined = bikes?.find(
        (bike) => bike.FrameNumber === currentBike.FrameNumber,
      );
      if (searchedBike !== undefined) {
        setExpandedBike([bikes?.indexOf(searchedBike)] ?? [0]);
      }
    } else {
      setExpandedBike([0]);
    }
  }, [bikes]);

  const handleChange = (panel: number) => () => {
    if (user && user.Frames) {
      dispatch(bikeActions.setActiveBike(user.Frames[panel].FrameNumber));
      if (expandedBike) {
        if (!expandedBike.includes(panel)) {
          setExpandedBike([...expandedBike, panel].sort());
        } else {
          const newArray = expandedBike;
          newArray.splice(expandedBike.indexOf(panel), 1);
          setExpandedBike(newArray);
        }
      }
    }
  };

  return (
    <>
      <PageLayout>
        {orders && activeOrders !== undefined && activeOrders.length > 0 && (
          <OrderTable classes={classes} orders={activeOrders} />
        )}
        <Typography variant="h3" className={classes.header}>
          {`${t('userDetailPage_header').toUpperCase()}`}
        </Typography>
        {user &&
          bikes &&
          bikes.length > 0 &&
          bikes.map((bike: BikeType, index) => (
            <>
              <Accordion
                key={bike.FrameNumber}
                className={classes.accordion}
                elevation={0}
                expanded={expandedBike !== null && expandedBike.includes(index)}
                onChange={handleChange(index)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.accordionSummary}
                >
                  <Typography className={classes.SkuName}>
                    {bike.SKU?.Name ?? bike.FrameNumber}
                  </Typography>
                  {bike.SKU === undefined && (
                    <ConnectFrameModal FrameNumber={bike.FrameNumber} />
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={classes.actionButtons}>
                    <BikeOwnerInfo
                      name={`${user.FirstName} ${user.LastName}`}
                      className={classes.tableContainer}
                      bike={bike}
                    />
                    <Divider className={classes.divider} />
                    <Box className={classes.documentLinks}>
                      <Link
                        variant="body1"
                        href="/build_manual_cs.pdf"
                        download
                      >
                        {t('userDetailPage_links_buildManual')}
                      </Link>
                      <Link variant="body1" href="/manual_cs.pdf" download>
                        {t('userDetailPage_links_manual')}
                      </Link>
                    </Box>
                    <Divider className={classes.divider} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          ))}
        {user && <AddFrameModal user={user} />}
      </PageLayout>
    </>
  );
};

export default withRouter(UserDetailPage);
