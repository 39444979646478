import { BikeType } from '../types/bike.types';
import { OrderFEType, UserInfoType } from '../store/initialState';
import { userConstants } from './types';

function login(Email: string): (dispatch: any) => void {
  function request(user: { Email: string }) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  return (dispatch: any) => {
    dispatch(request({ Email }));
  };
}

export const logout = (): { type: string } => ({
  type: userConstants.LOGOUT,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setUserData(userData: any) {
  return {
    type: userConstants.SET_USER_DATA,
    payload: {
      ...userData,
    },
  };
}

export const setFrames = (
  Frames: BikeType[],
): { type: string; payload: BikeType[] } => ({
  type: userConstants.SET_OWNED_BIKES,
  payload: Frames,
});

const setOrders = (Orders: any[]): { type: string; payload: any[] } => ({
  type: userConstants.SET_USER_ORDERS,
  payload: Orders,
});

const updateOrders = (
  Orders: OrderFEType[],
): { type: string; payload: OrderFEType[] } => ({
  type: userConstants.UPDATE_USER_ORDERS,
  payload: [...Orders],
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const deleteUserData = () => ({
  type: userConstants.DELETE_USER_DATA,
});

const updateUser = (userData: Partial<UserInfoType>) => ({
  type: userConstants.UPDATE_USER_DATA,
  payload: userData,
});

export const userActions = {
  login,
  setFrames,
  deleteUserData,
  updateUser,
  setOrders,
  updateOrders,
};
