import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { useTranslation } from 'react-i18next';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { OrderFEType } from '../../../../store/initialState';
import { OrderTableRow } from './orderTableRow';

const OrderTable = (props: {
  classes: ClassNameMap<
    | 'container'
    | 'bikeDetail'
    | 'accordion'
    | 'divider'
    | 'header'
    | 'SkuName'
    | 'actionButtons'
    | 'tableContainer'
    | 'accordionSummary'
    | 'documentLinks'
  >;
  orders: OrderFEType[];
}) => {
  const { t } = useTranslation();
  const { orders } = props;

  return (
    <>
      <Typography variant="h3" className={props.classes.header}>
        {t('userDetailPage_header_orders')}
      </Typography>
      <TableContainer component={Paper} style={{ marginBottom: '50px' }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{t('userDetailPage_orderList_orderPrice')}</TableCell>
              <TableCell>{t('userDetailPage_orderList_orderPaid')}</TableCell>
              <TableCell>{t('userDetailPage_orderList_orderStatus')}</TableCell>
              <TableCell>
                {t('userDetailPage_orderList_serviceAddress')}
              </TableCell>
              <TableCell>{t('userDetailPage_orderList_dropOffDate')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order, index) => {
              if (order.OrderStateCanceled === null) {
                return (
                  <OrderTableRow order={order} key={`${order._id}-${index}`} />
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default OrderTable;
