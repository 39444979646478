import {
  AppBar,
  Box,
  Container,
  IconButton,
  Link as MuiLink,
  Link,
  Toolbar,
  Typography,
  useMediaQuery,
  Drawer,
} from '@material-ui/core';
import React, { ComponentProps, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import MenuIcon from '@material-ui/icons/Menu';
import { LogoComponent } from '../../assets/logo';
import { ReduxStateType } from '../../store/initialState';
import UserSettingsModal from '../../components/organisms/userSettingsModal/userSettingsModal';
import { LanguageSwitcher } from '../../components/molecules/languageSwitcher/languageSwitcher';
import { LogoutButton } from '../../components/molecules/logoutButton/logoutButton';
import { RedirectPopover } from '../../components/molecules/redirectPopover/redirectPopover';
import { useStyles } from './pageLayout.styles';

export const PageLayout = (props: ComponentProps<any>): JSX.Element => {
  const classes = useStyles();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const { t } = useTranslation();
  const matches = useMediaQuery('(min-width:600px)');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!user) {
      setAnchorEl(event.currentTarget);
    } else {
      history.push('/');
    }
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirectToBasket = () => {
    history.push('/basket');
    window.location.reload();
  };

  // Logo popover variables
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar variant="dense" className={classes.toolbar}>
          <IconButton onClick={handleClick}>
            <LogoComponent
              width="50px"
              height="50px"
              className={classes.logo}
            />
          </IconButton>
          <RedirectPopover
            id={id}
            open={open}
            handleClose={handleClose}
            anchorEl={anchorEl}
          />
          <Link href="https://www.sava-bicycles.com/" target="_blank">
            <Typography variant="button">Web Sava</Typography>
          </Link>
          {matches && (
            <Box className={classes.actionBar}>
              {user &&
                (user.ServiceGroup === undefined ||
                  user.ServiceGroup.length === 0) && (
                  <IconButton onClick={handleRedirectToBasket}>
                    <ShoppingCartRoundedIcon />
                    {user.OrderDraft?.length > 0 && (
                      <Box>{user.OrderDraft?.length}</Box>
                    )}
                  </IconButton>
                )}
              {user &&
                (user.ServiceGroup === undefined ||
                  user.ServiceGroup.length === 0) && <UserSettingsModal />}
              {user && <LogoutButton />}
              <LanguageSwitcher />
            </Box>
          )}
          {!matches && (
            <>
              <IconButton
                onClick={() => {
                  toggleDrawer(true);
                }}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor={'right'}
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
              >
                {process.env.HIDE_BASKET === 'true' &&
                  user &&
                  (user.ServiceGroup === undefined ||
                    user.ServiceGroup.length === 0) && (
                    <IconButton onClick={handleRedirectToBasket}>
                      <ShoppingCartRoundedIcon />
                      {user.OrderDraft?.length > 0 && (
                        <Box>{user.OrderDraft?.length}</Box>
                      )}
                    </IconButton>
                  )}
                {user &&
                  (user.ServiceGroup === undefined ||
                    user.ServiceGroup.length === 0) && <UserSettingsModal />}
                {user && <LogoutButton />}
                <LanguageSwitcher />
              </Drawer>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Box className={classes.body}>
        <Container maxWidth={props.maxWidth ?? 'md'} className={classes.root}>
          {props.children}
        </Container>
      </Box>
      <Box className={classes.footer}>
        <Box className={classes.footerContent}>
          <Box className={classes.footerContentLinks}>
            <MuiLink
              variant="body2"
              href="/extendedWarrantyConditions_cs.pdf"
              download
            >
              {t('registerPage_Files_ExtendedWarrantyConditions')}
            </MuiLink>
            <MuiLink variant="body2" href="/warrantyTerms_cs.pdf" download>
              {t('registerPage_Files_WarrantyConditions')}
            </MuiLink>
            <MuiLink
              variant="body2"
              href="https://www.sava-bicycles.com/vop-vseobecne-obchodni-podminky"
              target="_blank"
            >
              {t('footer_TermsAndConditions')}
            </MuiLink>
          </Box>
          <Box className={classes.footerContentPartners}>
            <Typography>{t('footer_paymentPartnerText')}</Typography>
            <img
              width={'250px'}
              alt={'Payment gate strip'}
              src={`${process.env.PUBLIC_URL}/barion-card-strip-cz.svg`}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withTranslation()(PageLayout);
