import React from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CreateFrameRecordModal } from '../../../../components/organisms/createBikeRecordModal/createFrameRecordModal';
import { IFrameRecord } from '../../../../../../server/middleware/serviceGroup/getServiceGroup.mw';
import { ServiceType } from '../../../../../../server/models/ServiceType';
import { UserType } from '../../../../../../server/models/User';
import { BikeType } from '../../../../types/bike.types';

interface ServiceTaskTableRowProps {
  task: IServiceTaskForDashboard;
}

export interface IServiceTaskForDashboard {
  OrderNumber: string;
  FrameRecord?: IFrameRecord;
  ServiceFrom: Date;
  ServiceTo: Date;
  Id: string;
  Item: {
    Bike: BikeType;
    RequestedServiceTask: ServiceType | null;
  };
  Owner: Partial<UserType>;
  IsFinished: Date | null;
}

const useStyles = makeStyles({
  orderTableRow: {
    '&.orderPaid': {
      backgroundColor: '#ffc800',
      '& .MuiTableCell-body': {
        color: 'white',
      },
    },
    '&.orderPaid.orderFinished': {
      backgroundColor: 'green',
      '& .MuiTableCell-body': {
        color: 'white',
      },
    },

    '& td': {
      fontSize: '0.95rem',
    },
  },
  serviceItem: {
    '&.finished': {
      backgroundColor: 'green',
      color: 'white',
    },
  },
});

export const ServiceTaskTableRow = (props: ServiceTaskTableRowProps) => {
  const { task } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const handleEditServiceTask = () => {};

  return (
    <>
      <TableRow className={clsx(classes.orderTableRow)}>
        <TableCell>
          <IconButton onClick={handleEditServiceTask}>
            <CreateFrameRecordModal
              FrameRecordType={
                task.Item?.RequestedServiceTask?.ServiceType ?? ''
              }
              FrameRecord={task.FrameRecord}
              ServiceTask={task}
              Frame={task.Item.Bike}
              IsFinished={task.IsFinished !== null}
            />
          </IconButton>
        </TableCell>
        <TableCell>
          {moment(task.ServiceFrom).format('DD/MM/YY HH:mm')}
        </TableCell>
        <TableCell>{moment(task.ServiceTo).format('DD/MM/YY HH:mm')}</TableCell>
        <TableCell>{`${task.Owner.FirstName} ${task.Owner.LastName}`}</TableCell>
        <TableCell>{task.Owner.Phone}</TableCell>
        <TableCell>{task.Owner.Email}</TableCell>

        <TableCell component="th" scope="row">
          {task.Item.Bike.FrameNumber}
        </TableCell>

        <TableCell align="right">
          {t(`serviceType_${task?.Item?.RequestedServiceTask?.ServiceType}`)}
        </TableCell>
      </TableRow>
    </>
  );
};
