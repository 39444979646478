import axios from 'axios';

const createFrameRecord = (
  values: {
    FrameId: string;
    ServiceRecordDetail: string;
    IsEnabled: boolean;
    ServiceRecordType: string;
    ServiceTaskId: string;
  },
  prepare: boolean,
  handleSuccess: () => void,
  handleFailure: () => void,
) => {
  if (prepare) {
    axios
      .post(`/api/finishTask/`, {
        FrameId: values.FrameId,
        ServiceRecordDetail: values.ServiceRecordDetail,
        IsEnabled: values.IsEnabled,
        ServiceRecordType: values.ServiceRecordType,
        ServiceTaskId: values.ServiceTaskId,
        FinishTask: prepare,
      })
      .then((response) => {
        if (response.status === 200) {
          handleSuccess();
        } else {
          handleFailure();
        }
      });
  } else {
    axios
      .post(`/api/frameRecord/`, {
        FrameId: values.FrameId,
        ServiceRecordDetail: values.ServiceRecordDetail,
        IsEnabled: values.IsEnabled,
        ServiceRecordType: values.ServiceRecordType,
        ServiceTaskId: values.ServiceTaskId,
        FinishTask: prepare,
      })
      .then((response) => {
        if (response.status === 200) {
          handleSuccess();
        } else {
          handleFailure();
        }
      });
  }
};

const updateFrameRecord = (
  values: {
    RecordId: string;
    FrameId: string;
    ServiceRecordDetail: string;
    IsEnabled: boolean;
    ServiceRecordType: string;
    ServiceTaskId: string;
  },
  prepare: boolean,
  handleSuccess: () => void,
  handleFailure: () => void,
) => {
  axios
    .put(`/api/frameRecord/`, {
      RecordId: values.RecordId,
      FrameId: values.FrameId,
      ServiceRecordDetail: values.ServiceRecordDetail,
      IsEnabled: values.IsEnabled,
      ServiceRecordType: values.ServiceRecordType,
      ServiceTaskId: values.ServiceTaskId,
      FinishTask: prepare,
    })
    .then((response) => {
      if (response.status === 200) {
        handleSuccess();
      } else {
        handleFailure();
      }
    });
};

const FrameRecordService = {
  createFrameRecord: createFrameRecord,
  updateFrameRecord: updateFrameRecord,
};

export default FrameRecordService;
