import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RecordTypeEnum } from '../../../types/record.types';
import { BikeType } from '../../../types/bike.types';

export interface BikeDetailOwnerSectionProps {
  name: string;
  className?: string;
  bike: BikeType;
}

const useStyles = makeStyles(() => ({
  tableContainer: {
    width: '100%',
    '& .MuiTableCell-root': {
      border: 'none',
      padding: 5,
    },

    '& .MuiTableRow-root': {
      width: '100%',
    },

    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      fontWeight: 300,
      padding: 5,
    },
    '& .MuiTableRow-root .MuiTableCell-root:last-of-type': {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      textAlign: 'right',
    },
  },
}));

export const BikeOwnerInfo = ({
  name,
  bike,
}: BikeDetailOwnerSectionProps): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  let lastGarantService;
  let lastYearlyService;

  if (bike.Records) {
    lastGarantService = bike.Records.filter(
      (record) => record.ServiceRecordType === RecordTypeEnum.WarrantyRegular,
    )[0];
    lastYearlyService = bike.Records.filter(
      (record) => record.ServiceRecordType === RecordTypeEnum.Annual,
    )[0];
  }

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <tbody>
          <TableRow>
            <TableCell>{t('userDetailPage_ownerInfo_owner')}</TableCell>
            <TableCell>{name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>{t('userDetailPage_ownerInfo_FrameNumber')}</TableCell>
            <TableCell>{bike.FrameNumber}</TableCell>
          </TableRow>
          {bike.Registration && (
            <TableRow>
              <TableCell>
                {t('userDetailPage_ownerInfo_purchaseDate')}
              </TableCell>
              <TableCell>
                {new Date(bike.Registration).toLocaleDateString()}
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell>{t('userDetailPage_bikeInfo_garantService')}</TableCell>
            <TableCell>
              {lastGarantService && lastGarantService.Created
                ? new Date(lastGarantService.Created).toLocaleDateString()
                : t('general_no')}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              {t('userDetailPage_bikeInfo_yearlyCompleteService')}
            </TableCell>
            <TableCell>
              {lastYearlyService && lastYearlyService.Created
                ? new Date(lastYearlyService.Created).toLocaleDateString()
                : t('general_no')}
            </TableCell>
          </TableRow>
        </tbody>
      </Table>
    </TableContainer>
  );
};

export default BikeOwnerInfo;
