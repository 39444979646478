import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import { ReduxStateType } from '../../../store/initialState';
import { IServiceTaskForDashboard } from '../../../../../server/middleware/serviceGroup/getServiceGroup.mw';
import { ServiceGroupType } from '../basket/basket.model';
import { ServiceTaskTableRow } from './serviceTaskTable/serviceTaskRow';
import { ServiceTaskTableHeader } from './serviceTaskTable/serviceTaskTableHeader';

enum TaskFilter {
  Prepared = 'prepared',
  Todo = 'todo',
  All = 'all',
}

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 700,
    fontSize: 40,
    margin: '20px 0 10px 0',
    textAlign: 'center',
    marginBottom: 30,
  },

  filterWrapper: {
    marginBottom: 10,
  },
}));

const ServiceDashboard = () => {
  const classes = useStyles();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const [
    userServiceGroup,
    setUserServiceGroup,
  ] = useState<ServiceGroupType | null>(null);
  const [serviceGroupTasks, setServiceGroupTasks] = useState<
    IServiceTaskForDashboard[] | null
  >(null);
  const [filter, setFilter] = useState(TaskFilter.Todo);
  const { t } = useTranslation();

  const handleSetFilter = (event: any, filter: any) => {
    setFilter(filter);
  };

  useEffect(() => {
    if (user?.ServiceGroup !== undefined) {
      axios
        .get(`/api/serviceGroup/`, {
          params: {
            ServiceGroupId: user?.ServiceGroup,
          },
        })
        .then((response) => {
          setServiceGroupTasks(response.data.ServiceTasks);
          setUserServiceGroup(response.data);
        });
    }
  }, []);

  const getFilteredTasks = () => {
    return (
      serviceGroupTasks &&
      serviceGroupTasks.map((orderItem: any, index: number) => {
        if (filter === 'prepared') {
          if (orderItem.IsFinished !== null) {
            return (
              <ServiceTaskTableRow
                task={orderItem}
                key={`${orderItem.key}-${index}`}
              />
            );
          }
        } else if (filter === 'todo') {
          if (orderItem.IsFinished === null) {
            return (
              <ServiceTaskTableRow
                task={orderItem}
                key={`${orderItem.key}-${index}`}
              />
            );
          }
        } else {
          return (
            <ServiceTaskTableRow
              task={orderItem}
              key={`${orderItem.key}-${index}`}
            />
          );
        }
      })
    );
  };

  return (
    <PageLayout maxWidth={'lg'}>
      {userServiceGroup && (
        <>
          <Typography variant={'h1'} className={classes.header}>
            {`${t('serviceDashboard_header')} ${userServiceGroup.Name}`}
          </Typography>
          {serviceGroupTasks !== null && (
            <>
              <ToggleButtonGroup
                value={filter}
                exclusive
                onChange={handleSetFilter}
                aria-label="text alignment"
                className={classes.filterWrapper}
              >
                <ToggleButton
                  value={TaskFilter.Prepared}
                  aria-label="left aligned"
                >
                  {t('serviceDashboard_filter_prepared')}
                </ToggleButton>
                <ToggleButton value={TaskFilter.Todo} aria-label="centered">
                  {t('serviceDashboard_filter_todo')}
                </ToggleButton>
                <ToggleButton value={TaskFilter.All} aria-label="right aligned">
                  {t('serviceDashboard_filter_all')}
                </ToggleButton>
              </ToggleButtonGroup>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table">
                  <ServiceTaskTableHeader />
                  <TableBody>{getFilteredTasks()}</TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </PageLayout>
  );
};

export default withRouter(ServiceDashboard);
