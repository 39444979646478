import { userConstants } from '../actions/types';
import { ReduxStateType, UserInfoType } from '../store/initialState';

const {
  SET_OWNED_BIKES,
  SET_USER_DATA,
  LOGOUT,
  UPDATE_USER_DATA,
  SET_USER_ORDERS,
  UPDATE_USER_ORDERS,
} = userConstants;

export const users = (
  state: ReduxStateType | Record<string, UserInfoType> = {},
  action: any,
) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, ...action.payload };
    case SET_OWNED_BIKES:
      return {
        ...state,
        Frames: action.payload,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        userInfo: { ...action.payload },
      };
    case SET_USER_ORDERS:
      return {
        ...state,
        Orders: [...action.payload],
      };
    case UPDATE_USER_ORDERS:
      return {
        ...state,
        Orders: [...action.payload],
      };
    case LOGOUT:
      return null;
    default:
      return state;
  }
};
