import { bikesConstants } from 'src/actions/types';

export const bikeReducer = (state = {}, action: any) => {
  switch (action.type) {
    // case bikesConstants.UPDATE_BIKE_RECORDS:
    //     return {
    //         ...state,
    //         records: [...state.records, action.payload],
    //     };
    // case bikesConstants.UPDATE_BIKE_OWNER:
    //     return {
    //         ...state,
    //         ownerId: action.payload,
    //     };
    case bikesConstants.SET_ACTIVE_BIKE:
      return {
        ...state,
        bike: action.payload,
      };
    default:
      return state;
  }
};
