import { Button, ButtonProps, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: 0,
    fontWeight: 'bold',
    width: '100%',
    marginTop: 10,
    height: 50,
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    boxShadow: 'none',

    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      boxShadow: 'none',
    },

    '&.Mui-disabled': {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.12)',
      boxShadow: 'none',
    },
  },
}));

export interface BlackOutlinedButton extends ButtonProps {
  className?: string;
  dataFlag?: string;
}

export const BlackOutlinedButton = (
  props: BlackOutlinedButton,
): JSX.Element => {
  const classes = useStyles();
  const { dataFlag } = props;
  return (
    <Button
      data-flag={dataFlag}
      {...props}
      className={clsx(classes.root, props.className)}
    >
      {props.children}
    </Button>
  );
};
