import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

export const LogoComponent = (props: SvgIconProps): JSX.Element => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 97 88.09" {...props}>
    <path
      fill="#231916"
      fillRule="evenodd"
      d="M58.37,100.29a45,45,0,0,0,27.26-9.13c-18.19,0-35.9,0-54.53,0a45.21,45.21,0,0,0,27.27,9.16ZM99.19,74.76a44.93,44.93,0,0,0,4.6-19.84,45.52,45.52,0,0,0-29.95-42.7l-4,12L58.39,58.14,47,24.22l-4-12a45.33,45.33,0,0,0-25.2,62.94l.12-.06a2.85,2.85,0,0,1,1.1-.32H33.26a31.91,31.91,0,0,1,1-40.89L43.5,61.54l.92,2.73c2.1,6.29,8,9.37,14,9.38s11.87-3.07,14-9.36l.91-2.74,9.3-27.66a32,32,0,0,1,1,40.87Z"
      transform="translate(-9.89 -12.2)"
    />
    <path
      fill="#231916"
      fillRule="evenodd"
      d="M89.8,77.31a.25.25,0,0,0-.28-.22l-4.62,0a.66.66,0,0,0-.43.16l-12,8.33H70l-3-8.44c0-.1-.13-.14-.26-.15L62,77a.78.78,0,0,0-.51.22.42.42,0,0,0-.17.4l4.08,11.2a.27.27,0,0,0,.27.15l7.45,0a.77.77,0,0,0,.42-.15l16-11.08A.6.6,0,0,0,89.8,77.31Z"
      transform="translate(-9.89 -12.2)"
    />
    <path
      fill="#231916"
      fillRule="evenodd"
      d="M102.8,77.14a.27.27,0,0,0-.27-.14l-7.45,0a.84.84,0,0,0-.43.14l-16,11.09a.56.56,0,0,0-.27.4c0,.12.11.21.28.22l4.63,0a.72.72,0,0,0,.42-.15L85,87.85l15.91,0,.33.92c0,.1.12.15.26.15l4.74,0a.92.92,0,0,0,.52-.22c.13-.13.21-.29.15-.41ZM89.36,84.8l6.41-4.46h2.44l1.58,4.46Z"
      transform="translate(-9.89 -12.2)"
    />
    <path
      fill="#231916"
      fillRule="evenodd"
      d="M35.55,83.18,34,81.57a.41.41,0,0,0-.22-.08l-14.55,0-.09-.14.64-1.18L20,80l17,.06a.73.73,0,0,0,.65-.37l1.26-2.33c.13-.25,0-.39-.26-.39H18.86a.76.76,0,0,0-.35.09l-3.24,1.75a.84.84,0,0,0-.3.29l-1.76,3.28c-.08.13-.07.26,0,.32L15.3,84.3a.29.29,0,0,0,.19,0l14,.07.07.13-.66,1.23-.2.11L11.8,85.83a.81.81,0,0,0-.65.37L9.94,88.46a.24.24,0,0,0,.22.38l19.62.08a.93.93,0,0,0,.35-.08L33.54,87a.84.84,0,0,0,.31-.28l1.71-3.2A.25.25,0,0,0,35.55,83.18Z"
      transform="translate(-9.89 -12.2)"
    />
    <path
      fill="#231916"
      fillRule="evenodd"
      d="M58,77l-7.44,0a.71.71,0,0,0-.43.14l-16,11.09a.51.51,0,0,0-.27.4c0,.13.11.21.28.21l4.62,0a.75.75,0,0,0,.42-.14l1.21-.85,15.91,0,.32.93c0,.1.13.14.27.15l4.74,0a.84.84,0,0,0,.52-.22c.13-.13.21-.29.16-.4l-4.08-11.2A.25.25,0,0,0,58,77Zm-13.18,7.8,6.43-4.46h2.44l1.57,4.46Z"
      transform="translate(-9.89 -12.2)"
    />
    <path
      fill="#e60013"
      fillRule="evenodd"
      d="M32.29,17.77,47.5,63.19a11.86,11.86,0,0,0,1,2.08c3.2,5,9.78,6.19,15,4a10.24,10.24,0,0,0,5.7-6.1L84.49,17.82a45.39,45.39,0,0,0-10.69-5.6l-4,12L58.36,58.15,47,24.22l-4-12a45.26,45.26,0,0,0-10.66,5.57Z"
      transform="translate(-9.89 -12.2)"
    />
  </SvgIcon>
);
