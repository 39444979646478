import axios from 'axios';

interface VerifyUserType {
  UserId: string;
  TemporaryToken: string;
}

export const userVerification = async ({
  UserId,
  TemporaryToken,
}: VerifyUserType) => {
  try {
    const res = axios.put(`/api/verify/${UserId}/${TemporaryToken}`);
    return res;
  } catch (err) {
    return err.response;
  }
};

export const resendUserVerificationEmail = async ({
  UserId,
}: VerifyUserType) => {
  try {
    const res = axios.get(`/api/verify/${UserId}`);
    return res;
  } catch (err) {
    return err.response;
  }
};
