import axios from 'axios';

interface LoginType {
  Login: string;
  Password: string;
}

export const userLogin = async ({ Login, Password }: LoginType) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/login`, {
      Login,
      Password,
    });
    return res;
  } catch (err) {
    return err.response;
  }
};
