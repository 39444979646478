import { bikesConstants } from './types';

const setActiveBike = (FrameNumber: string) => ({
  type: bikesConstants.SET_ACTIVE_BIKE,
  payload: {
    FrameNumber,
  },
});

export const bikeActions = {
  setActiveBike,
};
