import { useDispatch } from 'react-redux';
import { snackbarActions } from '../actions/snackBar.actions';

interface UseSnackBarType {
  displaySnackbar: (type: string, label: string) => void;
  hideSnackbar: () => void;
}

export const useSnackbar = (): UseSnackBarType => {
  const dispatch = useDispatch();
  const displaySnackbar = (type: string, label: string) => {
    dispatch(snackbarActions.setAllData({ show: true, type, label }));
    setTimeout(() => {
      dispatch(snackbarActions.setShow(false));
    }, 2000);
  };

  const hideSnackbar = () => {
    dispatch(snackbarActions.setShow(false));
  };

  return { displaySnackbar, hideSnackbar };
};
