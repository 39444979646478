export enum RecordTypeEnum {
  Build = 'compose',
  WarrantySponsored = 'warrantyServiceSponsored',
  WarrantyRegular = 'warrantyService',
  Annual = 'annualService',
  Diagnostics = 'diagnosticsService',
}

export interface Record {
  _id: number;
  Type: RecordTypeEnum;
  Title: string;
  Message: string;
  Created: Date;
}

export interface IPrice {
  Currency: string;
  Amount: number;
}

export interface SupportedServiceType {
  FEType: string;
  Type: RecordTypeEnum;
  Duration: number;
  Translation: string;
  Price: IPrice[];
}
export const SupportedServices = {
  Build: {
    FEType: 'Build',
    Type: RecordTypeEnum.Build,
    Duration: 72,
    Translation: 'serviceType_compose',
    Price: 1,
  },
  WarrantyRegular: {
    FEType: 'WarrantyRegular',
    Type: RecordTypeEnum.WarrantyRegular,
    Duration: 36,
    Translation: 'serviceType_warrantyService',
    Price: 2,
  },
  WarrantySponsored: {
    FEType: 'WarrantySponsored',
    Type: RecordTypeEnum.WarrantySponsored,
    Duration: 36,
    Translation: 'serviceType_warrantySponsored',
    Price: 3,
  },
  Annual: {
    FEType: 'Annual',
    Type: RecordTypeEnum.Annual,
    Duration: 48,
    Translation: 'serviceType_annualService',
    Price: 4,
  },
  Diagnostics: {
    FEType: 'Diagnostics',
    Type: RecordTypeEnum.Diagnostics,
    Duration: 48,
    Translation: 'serviceType_diagnosticsService',
    Price: 4,
  },
};
