import { createStyles, makeStyles } from '@material-ui/core';
import { SavaTheme } from '../../theme/theme';

export const useStyles = makeStyles((theme: SavaTheme) =>
  createStyles({
    body: {
      minHeight: 'calc(100vh - 200px)',
      backgroundColor: 'white',
      maxWidth: '100vw',
      padding: 20,
    },
    root: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    appBar: {
      display: 'flex',
      '&.MuiAppBar-colorPrimary': {
        backgroundColor: '#f4f4f4',
      },
      color: 'black',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px 20px',
      maxWidth: 1280,
      alignSelf: 'center',
      width: '100%',

      // override default padding for logo button
      '& .MuiIconButton-root': {
        padding: 0,
      },
    },
    actionBar: {
      '& .MuiIconButton-root': {
        minWidth: 59,
        height: 59,
        width: 59,
        padding: 5,
        borderRadius: '100%',

        '& .MuiButton-label .MuiTypography-button': {
          fontWeight: theme.typography.fontWeightBold,
          fontSize: '1.2rem',
          color: '#2f2f2f',
        },
      },
    },
    logo: {
      width: 90,
      height: 90,
    },
    footer: {
      height: 100,
      backgroundColor: 'black',

      '& .MuiLink-root': {
        color: 'white',
      },
    },
    footerContent: {
      width: '100%',
      maxWidth: 1280,
      margin: 'auto',
      padding: 20,
      display: 'flex',
      justifyContent: 'space-between',
    },

    footerContentLinks: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },

    footerContentPartners: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',

      '& p': {
        color: 'white',
      },
    },
  }),
);
