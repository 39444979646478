import { BikeType } from '../types/bike.types';
import { ServiceGroupType } from '../../../server/models/ServiceGroup';

export const initialState = {
  snackbarData: {
    show: false,
    label: '',
    type: '',
  },
  userInfo: null,
  bike: '',
};

export interface SnackBarProps {
  show: boolean;
  label: string;
  type: 'error' | 'info' | 'warning' | 'success';
}

export interface ReduxStateType {
  snackbarData: Partial<SnackBarProps>;
  userInfo: UserInfoType | null;
  bike: {
    bike: {
      FrameNumber: string;
    };
  };
}

export enum OrderItemType {
  WarrantyServiceSponsored = 'warrantyservicesponsored',
  WarrantyService = 'warrantyservice',
  YearlyService = 'yearlyService',
  BikeCompletion = 'bikeCompletion',
}

export interface OrderItem {
  Frame: string;
  SKU: string;
  RequestedServiceType: OrderItemType;
}

export interface UserInfoType {
  _id: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Token: string;
  Admin: boolean;
  Frames: Array<BikeType>;
  GdprCheck: Date | null;
  Newsletter: Date | null;
  ServiceInfoMail: Date | null;
  Phone: string;
  OrderDraft: Array<OrderItem>;
  LastBillingAddress?: BillingAddress;
  Orders: OrderFEType[];
  ServiceGroup?: string[];
}

export interface BillingAddress {
  Street: string;
  StreetNumber: string;
  ZIPCode: string;
  Country: { code: string; label: string; phone: string } | null;
}

export interface OrderFEType {
  _id: string;
  Owner: string;
  OrderStateCreated: Date;
  OrderStateCanceled: Date;
  OrderStatePaid: Date;
  OrderStateRefund: Date;
  OrderStateFinished: Date;
  GateInfo: string;
  ServiceGroup: ServiceGroupType;
  ServiceFrom: Date;
  ServiceTo: Date;
  BarionPaymentRequest: string;
  BarionPaymentResponse: string;
  ServiceState: string;
  ServiceStateDate: Date;
  ServiceTask: any[];
  OrderItem: OrderItem[];
  OrderNumber: number;
  OrderPrice:
    | string
    | {
        Price: number;
        Currency: string;
      };
}
