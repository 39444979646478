import { makeStyles } from '@material-ui/core';
import { SavaTheme } from '../../../theme/theme';

export const useStyles = makeStyles((theme: SavaTheme) => ({
  basketSectionPanel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0 3px 6px #00000033',
    width: '100%',
    padding: 20,
    flexGrow: 1,
    marginBottom: 20,

    '&.orderItems': {
      flexDirection: 'column',
    },

    '&.map': {
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },

    '&.payment': {
      flexDirection: 'column-reverse',
      '& button': {
        marginBottom: 15,
      },
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        marginBottom: 0,
      },
    },

    '&.itemSelection': {
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
  },
  input: {
    marginBottom: 10,
  },

  basketSectionRow: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    height: 75,
    justifyContent: 'space-around',
    alignItems: 'space-between',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',

      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },

    '&.sum': {
      paddingRight: 52,
    },
  },

  frameSelectionSectionFormControl: {
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      marginRight: 15,
      marginBottom: 0,
    },

    '& .MuiInputLabel-root': {
      paddingLeft: 15,
    },

    '& ..MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },

  frameSelectionSection: {
    flexGrow: 1,
    width: '100%',
    marginBottom: 15,
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginRight: 15,
      marginBottom: 0,
    },
  },
  orderItemsSelection: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      flexGrow: 3,
      '& button': {
        maxWidth: 180,
        borderRadius: 10,
        marginTop: 0,
      },
      '& button.selected': {
        backgroundColor: '#000000',
        color: '#FFFFFF',
      },
      '& button:not(:last-of-type)': {
        marginRight: 15,
      },
      marginBottom: 0,
    },
  },

  orderFrameNumber: {
    display: 'inline-flex',
    width: '50%',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 'larger',
  },

  priceSection: {
    display: 'flex',
    flexDirection: 'column-reverse',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    '& .totalPrice': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.5rem',
    },

    [theme.breakpoints.up('sm')]: {
      width: '15%',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      flexGrow: 1,
    },
  },
  map: {
    height: 500,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 600,
      width: '50%',
    },
  },
  selectedServiceGroup: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 600,
      width: '50%',
    },
    display: 'flex',
    flexDirection: 'column',
    padding: '0 15px',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },

  serviceInfo: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '&.Empty': {
      justifyContent: 'center',
    },
  },
  dateSelectionRow: {
    display: 'flex',
    marginBottom: 10,
  },
  timeSelectionRow: {
    display: 'flex',
  },
  dateSelectionItem: {
    border: '1px solid black',
    padding: 5,
    boxSizing: 'border-box',
    '&:hover:not(.selected)': {
      backgroundColor: 'black',
      color: 'white',
      cursor: 'pointer',
    },

    '&.selected': {
      backgroundColor: 'black',
      color: 'white',
    },
    '& p': {
      textAlign: 'center',
      lineHeight: '1.3rem',
    },
  },
  weekSchedule: {
    display: 'flex',
    width: '75%',
    justifyContent: 'flex-start',
    '&:first-of-type': {
      marginTop: 30,
    },
  },
  weekScheduleWeekday: {
    marginRight: 10,
  },

  weekScheduleDay: {
    display: 'flex',
    width: '90%',
    justifyContent: 'flex-start',
  },
  weekScheduleDayDuty: {
    display: 'flex',
    width: 'fit-content',
    marginRight: 5,
    justifyContent: 'flex-start',

    '& p:first-of-type': {
      marginRight: 5,
    },
  },

  dateSelection: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 15,

    '& p': {
      width: '100%',
    },

    '& .MuiFormControl-root': {
      width: '100%',
      marginBottom: 15,
      [theme.breakpoints.up('md')]: {
        marginRight: 15,
        marginBottom: 0,
        width: '45%',
      },

      '& .MuiInputLabel-formControl': {
        paddingLeft: 10,
      },

      '& .MuiInputLabel-shrink': {
        display: 'none',
      },

      '& .MuiSelect-selectMenu:placeholder': {
        paddingLeft: 10,
      },
    },
  },
  submitOrderButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    borderRadius: 5,

    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },

    padding: 5,
    height: 'auto',
    '& span': {
      display: 'block',
    },
    display: 'flex',
    flexDirection: 'column',
  },
}));
