import { combineReducers } from 'redux';

import { users } from './user.reducer';
import { snackbarReducer } from './snackbar.reducer';
import { bikeReducer } from './bike.reducer';

export const rootReducer = combineReducers({
  userInfo: users,
  snackbarData: snackbarReducer,
  bike: bikeReducer,
});
