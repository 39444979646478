import moment, { Moment } from 'moment';
import { DutyHours, ServiceGroupType } from '../basket.model';

/**
 * Gets available drop-off and pick-up dates
 * @param service
 * @param initialDate
 */
const getAvailableDates = (
  service: ServiceGroupType | null,
  initialDate?: Date,
): Date[] => {
  let availableDates: Date[] = [];
  if (initialDate) {
    if (service) {
      availableDates = getDatesForDateFrame(0, service, initialDate);
    }
  } else {
    if (service) {
      const nextDrop = service.NextAvailableDrop / 24;
      availableDates = getDatesForDateFrame(nextDrop, service);
    }
  }
  return availableDates;
};

/**
 * Gets dates for given frametime
 * @param TimeFrameStartOffset - sets offset of the calculated timeframe in days
 * @param service
 * @param initialDate
 */
const getDatesForDateFrame = (
  TimeFrameStartOffset: number,
  service: ServiceGroupType,
  initialDate?: Date,
): Date[] => {
  let availableDates: Date[] = [];
  const currentDate = initialDate ? new Date(initialDate) : new Date();
  for (let i = TimeFrameStartOffset; i < TimeFrameStartOffset + 14; i++) {
    const DropOffDate = new Date(currentDate);
    const actualDate: Date = moment(DropOffDate).add(i, 'days').toDate();
    DropOffDate.setDate(actualDate.getDate());
    const DayInWeek = getConvertedWeekday(DropOffDate.getDay());
    const closingTimeString = getServiceClosingTime(service.Duty, DayInWeek);
    const closingTimeHour = moment(closingTimeString, 'hh:mm').get('hours');
    if (i === 0 && DropOffDate.getHours() > closingTimeHour) {
      continue;
    }
    if (
      service?.Duty[DayInWeek] !== undefined &&
      service.Duty[DayInWeek].length > 0
    ) {
      if (i > 0) {
        DropOffDate.setHours(0);
        availableDates = [...availableDates, DropOffDate];
      } else {
        availableDates = [...availableDates, DropOffDate];
      }
    }
  }
  return availableDates;
};

const getServiceClosingTime = (dutyHours: DutyHours[][], weekday: number) => {
  return dutyHours[weekday].length
    ? dutyHours[weekday][dutyHours[weekday].length - 1].close
    : '00:00';
};

const getNextOpenTime = (dutyHours: DutyHours[][], weekday: number) => {
  let currentWeekday = weekday;
  let counter = 0;

  while (!dutyHours[currentWeekday].length) {
    if (currentWeekday >= 6) {
      currentWeekday = 0;
    } else {
      currentWeekday++;
    }
    counter++;
  }
  return { daysPassed: counter, openTime: dutyHours[currentWeekday][0].open };
};

function getTimeIntervalsForDay(
  openInterval: DutyHours,
  dropOffTime: moment.Moment | undefined,
  timeLabels: string[],
) {
  const startTimeMoment = moment(openInterval.open, 'hh:mm');
  const endTimeMoment = moment(openInterval.close, 'hh:mm');
  const periodsInADay = moment
    .duration(endTimeMoment.diff(startTimeMoment, 'minutes'), 'minutes')
    .as('minutes');
  const interval = 30;
  for (let i = 0; i <= periodsInADay; i += interval) {
    const timeSlot = startTimeMoment.add(i === 0 ? 0 : interval, 'minutes');
    if (
      dropOffTime !== undefined &&
      moment(dropOffTime).get('hours') <= timeSlot.get('hours')
    ) {
      timeLabels.push(timeSlot.format('HH:mm'));
    }
    if (dropOffTime === undefined) {
      timeLabels.push(timeSlot.format('HH:mm'));
    }
  }
}

const getTimeSlots = (
  weekday: number,
  serviceGroup: ServiceGroupType,
  dropOffTime?: Moment,
) => {
  const timeLabels: string[] = [];
  const convertedWeekday = getConvertedWeekday(weekday);
  if (
    serviceGroup.Duty[convertedWeekday] !== undefined &&
    serviceGroup.Duty[convertedWeekday].length > 0
  ) {
    serviceGroup?.Duty[convertedWeekday].forEach((openInterval) => {
      getTimeIntervalsForDay(openInterval, dropOffTime, timeLabels);
    });
  }
  return timeLabels;
};

/**
 * Returns pickup dates starting with closes one based on longest service
 * @param dropOffDate
 * @param service
 * @param longestService
 */
const getPickupDate = (
  dropOffDate: Date,
  service: ServiceGroupType | null,
  longestService: number,
) => {
  if (service) {
    // Add longest service time to dropOff date
    let nextPickupDate = moment(dropOffDate).add(longestService, 'minutes');
    // Get closing time for next pickup date
    const closingTimeString = getServiceClosingTime(
      service.Duty,
      getConvertedWeekday(nextPickupDate.weekday()),
    );
    // Get closing hour for comparing
    const closingTimeHour = moment(closingTimeString, 'hh:mm').get('hours');
    // Check whether service is open
    const isServiceOpen =
      service.Duty[getConvertedWeekday(nextPickupDate.get('day'))].length > 0 &&
      nextPickupDate.get('hour') < closingTimeHour;
    if (isServiceOpen) {
      return getAvailableDates(service, nextPickupDate.toDate());
    } else {
      const nexOpenDay = getNextOpenTime(
        service.Duty,
        getConvertedWeekday(nextPickupDate.add(1, 'day').toDate().getDay()),
      );
      nextPickupDate = moment(nextPickupDate)
        .add(nexOpenDay.daysPassed, 'day')
        .set('hour', moment(nexOpenDay.openTime, 'hh:mm').get('hours'))
        .set('minute', moment(nexOpenDay.openTime, 'hh:mm').get('minutes'));
      return getAvailableDates(service, nextPickupDate.toDate());
    }
  }
};

const getWorkingHoursInDay = (dayDuty: DutyHours[] | undefined) => {
  let result = 0;
  if (dayDuty && dayDuty.length > 0) {
    dayDuty.forEach((interval) => {
      const endTime = moment(interval.close, 'hh:mm').get('hour');
      const startTime = moment(interval.open, 'hh:mm').get('hour');
      result += endTime - startTime;
    });
  }
  return result;
};

const getConvertedWeekday = (weekday: number) => {
  return (weekday + 6) % 7;
};

const TimeUtils = {
  getAvailableDates: getAvailableDates,
  getTimeSlots: getTimeSlots,
  getWorkingHoursInDay: getWorkingHoursInDay,
  getPickupDates: getPickupDate,
  getConvertedWeekday: getConvertedWeekday,
  getTimeIntervalsForDay: getTimeIntervalsForDay,
};

export default TimeUtils;
