import { snackbarConstants } from './types';

interface snackbarShowAllAction {
  show: boolean;
  type: string;
  label: string;
}

const setAllData = ({ show, type, label }: snackbarShowAllAction) => ({
  type: snackbarConstants.SET_ALL_DATA,
  payload: {
    show,
    type,
    label,
  },
});

const setShow = (showStatus: boolean) => ({
  type: snackbarConstants.SET_SHOW,
  payload: {
    show: showStatus,
  },
});

export const snackbarActions = {
  setAllData,
  setShow,
};
