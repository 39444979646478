import axios from 'axios';
import { BikeType } from '../types/bike.types';

const connectFrameToSKU = (
  FrameNumber: string,
  SKU: string,
  handleSuccess: () => void,
  handleFailure: (message: any) => void,
) => {
  axios
    .put('/api/bikes/connect/', {
      FrameNumber: FrameNumber,
      SKU: SKU,
    })
    .then((response) => {
      if (response.status === 200) {
        handleSuccess();
      }
    })
    .catch((e) => {
      handleFailure(e.response.data.message);
    });
};

const getBikes = (
  email: string | undefined,
  handleSuccess: (Frames: BikeType[]) => void,
  handleFailure: (errorMessage: string) => void,
) => {
  if (email !== undefined) {
    axios
      .get(`/api/bikes/${email}`)
      .then((response) => {
        const { Frames } = response.data;
        handleSuccess(Frames);
      })
      .catch((e) => {
        if (e.response.data === 'Invalid token') {
          localStorage.removeItem('user');
          window.location.href = '/';
        }
        handleFailure(e.response.data);
      });
  }
};

const FrameService = {
  connectFrameToSKU: connectFrameToSKU,
  getBikes: getBikes,
};

export default FrameService;
