import axios from 'axios';
import bcrypt from 'bcryptjs';

interface PasswordResetRequestType {
  Email?: string;
}

interface PasswordResetVerifyType {
  ResetPasswordToken?: string;
  Password?: string;
}

export const passwordResetRequest = async ({
  Email,
}: PasswordResetRequestType) => {
  if (Email) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/passwordResetRequest/${Email}`,
      );
      return res;
    } catch (err) {
      return err.response;
    }
  }
};

export const passwordResetGetUser = async ({
  ResetPasswordToken,
}: PasswordResetVerifyType) => {
  if (ResetPasswordToken) {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/passwordReset/${ResetPasswordToken}`,
      );
      return res;
    } catch (err) {
      return err.response;
    }
  }
};

export const passwordResetValidate = async ({
  ResetPasswordToken,
  Password,
}: PasswordResetVerifyType) => {
  if (ResetPasswordToken && Password) {
    const password = Password;
    const salt = bcrypt.genSaltSync(10);
    const hash = bcrypt.hashSync(password, salt);

    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/passwordReset/${ResetPasswordToken}`,
        {
          Password: hash,
        },
      );
      return res;
    } catch (err) {
      return err.response;
    }
  }
};
