import axios from 'axios';

interface UpdateUserType {
  Id: string;
  Form: {
    FirstName?: string;
    LastName?: string;
    Phone?: string;
    Newsletter: Date | null;
    ServiceInfoMail?: Date | null;
  };
}

export const userUpdate = async ({ Id, Form }: UpdateUserType) => {
  try {
    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/user/update/${Id}`,
      { Form },
    );
    return res;
  } catch (err) {
    return err.response;
  }
};

export const updateLocalUser = (newData: { [key: string]: any }) => {
  let localUser = JSON.parse(localStorage.getItem('user') as string);
  if (localUser) {
    localUser = { ...localUser, ...newData };
    localStorage.setItem('user', JSON.stringify(localUser));
  }
};
