import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  headerText: {
    fontSize: '0.9rem',
  },
}));

export const ServiceTaskTableHeader = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_taskDetail_ChangeStatus')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_ServiceFrom')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_ServiceTo')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_OwnerName')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_OwnerPhone')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_OwnerEmail')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_taskDetail_FrameNumber')}
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant={'button'} className={classes.headerText}>
            {t('serviceDashboard_taskDetail_RequestedService')}
          </Typography>
        </TableCell>
      </TableRow>
    </TableHead>
  );
};
