import { useDispatch } from 'react-redux';
import authenticationSvc from '../services/authentication';
import { setUserData } from '../actions/user.actions';
import { bikeActions } from '../actions/bike.actions';

interface HookType {
  onLoginSubmit: () => Promise<void>;
}

interface FormStateType {
  Login: string;
  Password: string;
}

export const useLogin = (
  formState: FormStateType,
  handleSuccess: (redirectUrl: string) => void,
  handleFailure: (loginRes: { status: number; data: string }) => void,
): HookType => {
  const { userLogin } = authenticationSvc;
  const dispatch = useDispatch();

  const onLoginSubmit = async () => {
    const loginRes = await userLogin(formState);
    if (loginRes.status === 200) {
      const fullUser = {
        ...loginRes.data.user,
        token: loginRes.data.token,
      };
      localStorage.setItem('user', JSON.stringify(fullUser));
      dispatch(setUserData(fullUser));
      dispatch(bikeActions.setActiveBike(loginRes.data.currentBike));
      if (
        fullUser.ServiceGroup !== undefined &&
        fullUser.ServiceGroup.length > 0
      ) {
        handleSuccess('/serviceDashboard');
      } else {
        handleSuccess('/dashboard');
      }
    } else if (loginRes.status === 400) {
      dispatch(setUserData(loginRes.data.user));
      handleFailure(loginRes);
    } else {
      handleFailure(loginRes);
    }
  };

  return { onLoginSubmit };
};
