import i18n from 'i18next';

const formatPrice = (
  price: number | undefined,
  serviceGroupCurrency: string | null = null,
) => {
  let orderCurrency = i18n.language === 'cs' ? 'CZK' : 'EUR';

  if (serviceGroupCurrency) {
    orderCurrency = serviceGroupCurrency;
  }

  if (price !== undefined) {
    return Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: orderCurrency,
    }).format(price);
  } else {
  }
};

const PriceUtils = {
  formatPrice: formatPrice,
};

export default PriceUtils;
