import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  container: {
    margin: 'auto',
  },
  header: {
    fontWeight: 700,
    fontSize: 40,
    margin: '20px 0 10px 0',
    textAlign: 'center',
    marginBottom: 30,
  },
  bikeDetail: {
    marginBottom: 15,
  },
  actionButtons: {
    width: '100%',
  },
  tableContainer: {
    width: '100%',
    '& .MuiTableCell-root': {
      border: 'none',
    },

    '& .MuiTableRow-root': {
      width: '100%',
    },

    '& .MuiTableRow-root .MuiTableCell-root:first-of-type': {
      fontWeight: 300,
      paddingRight: 20,
    },
    '& .MuiTableRow-root .MuiTableCell-root:last-of-type': {
      fontWeight: 500,
      textTransform: 'uppercase',
      textAlign: 'right',
    },
  },
  divider: {
    margin: '20px 0 20px',
  },
  accordion: {
    margin: '5px auto',
    width: '100%',
    '&:before': {
      display: 'none',
    },

    '&.Mui-expanded': {
      margin: '5px auto',
    },
  },
  accordionSummary: {
    '& .MuiAccordionSummary-content': {
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    '&:not(.Mui-expanded)': {
      borderBottom: '1px solid #dddddd',
    },

    '&:hover': {
      backgroundColor: '#f4f4f4',
    },
  },
  documentLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& a': {
      textDecoration: 'underline',
      lineHeight: '1.75rem',

      '&:hover': {
        cursor: 'pointer',
        fontWeight: 700,
      },
    },
  },
  SkuName: {
    fontSize: '1.8rem',
    fontWeight: 700,
  },
}));
