import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  IconButton,
  Snackbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useTranslation } from 'react-i18next';
import { frameNumberValidation } from '../../../services/authentication/frameNumberValidation.service';
import { setFrames } from '../../../actions/user.actions';
import { useAddBike } from '../../../hooks/useAddBike';
import { User } from '../../../types/user.types';
import FrameService from '../../../services/frame.service';
import { useStyles } from './addFrameModal.styles';
import { AddFrameFormType } from './addFrameModal.types';

interface ModalProps {
  user: User;
}

export const AddFrameModal = ({ user }: ModalProps): JSX.Element => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [snackBarOpen, setSnackBarOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const validate = (values: AddFrameFormType) => {
    const errors: { PurchaseDate?: string } = {};
    if (!values.PurchaseDate) {
      errors.PurchaseDate = t(
        'registerPagePage_form_input_error_mandatoryField',
      );
    }

    if (
      values.PurchaseDate &&
      new Date(values.PurchaseDate).valueOf() > Date.now().valueOf()
    ) {
      errors.PurchaseDate = t(
        'registerPagePage_form_input_error_dateOfPurchase',
      );
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      FrameNumber: '',
      PurchaseDate: new Date(),
    },
    validate,
    onSubmit: async () => {
      try {
        const FrameNumberResponse = await frameNumberValidation({
          FrameNumber: formik.values.FrameNumber.toUpperCase(),
        });
        if (FrameNumberResponse.status !== 204) {
          formik.errors.FrameNumber = t(
            'registerPagePage_form_input_error_FrameNumber',
          );
        }
        if (FrameNumberResponse.status === 204) {
          onSubmit().then((res) => console.log(res));
        }
      } catch (err) {
        console.log('Could not validate the form, please try again later');
      }
    },
  });

  const { onSubmit } = useAddBike(
    {
      UserId: user._id,
      FrameNumber: formik.values.FrameNumber.toUpperCase(),
      Registration: new Date(formik.values.PurchaseDate),
    },
    () => {
      setSnackBarOpen(true);
      FrameService.getBikes(
        user.Email,
        (Bikes) => {
          dispatch(setFrames([...Bikes]));
          handleClose();
        },
        (error) => {
          console.log(error);
        },
      );
    },
    () => {
      console.log('error', 'Please verify the credentials');
    },
  );

  const body = (
    <Box className={classes.root}>
      <Typography variant="h1" className={classes.header}>
        {t('addFrameModal_header')}
      </Typography>
      <form onSubmit={formik.handleSubmit} className={classes.registerForm}>
        <TextField
          fullWidth
          id="FrameNumber"
          name="FrameNumber"
          label={t('registerPagePage_form_input_placeholder_FrameNumber')}
          value={formik.values.FrameNumber}
          onChange={formik.handleChange}
          error={
            formik.touched.FrameNumber && Boolean(formik.errors.FrameNumber)
          }
          helperText={formik.touched.FrameNumber && formik.errors.FrameNumber}
          variant="filled"
          className={classes.input}
          required
        />
        <TextField
          fullWidth
          id="PurchaseDate"
          name="PurchaseDate"
          label={t('registerPagePage_form_input_placeholder_dateOfPurchase')}
          type="date"
          value={formik.values.PurchaseDate}
          onChange={formik.handleChange}
          error={
            formik.touched.PurchaseDate && Boolean(formik.errors.PurchaseDate)
          }
          helperText={formik.touched.PurchaseDate && formik.errors.PurchaseDate}
          variant="filled"
          className={classes.input}
          InputLabelProps={{
            shrink: true,
          }}
          required
        />
        <Button
          color="primary"
          variant="outlined"
          fullWidth
          type="submit"
          className={classes.submitButton}
        >
          {t('addFrameModal_button')}
        </Button>
      </form>
    </Box>
  );

  return (
    <>
      <Tooltip
        title={t('addFrameModal_addButton_tooltip').toString()}
        aria-label={t('addFrameModal_addButton_tooltip').toString()}
      >
        <IconButton
          onClick={() => setIsModalOpen(true)}
          className={classes.addButton}
        >
          <AddCircleIcon fontSize="large" fill="black" />
        </IconButton>
      </Tooltip>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} severity="success">
          {t('addFrameModal_snackBar_success')}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AddFrameModal;
