import authenticationSvc from '../services/authentication';

export const useRegistration = (
  formState: any,
  handleSuccess: any,
  handleFailure: any,
) => {
  const { userRegistration } = authenticationSvc;

  const onSubmit = async () => {
    const registrationRes = await userRegistration(formState);
    if (registrationRes.status === 200) {
      handleSuccess();
    } else {
      handleFailure(registrationRes);
    }
  };

  return { onSubmit };
};
