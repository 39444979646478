import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 1280,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 30,
  },
  header: {
    fontWeight: 700,
    fontSize: 40,
    margin: '20px 0 10px 0',
  },
  input: {
    marginTop: 20,
    '& input': { backgroundColor: 'white' },
    '& .MuiFormLabel-root.Mui-focused': {
      color: 'black',
    },
    '&.Mui-focused': {
      borderBottom: '2px solid black',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottom: '2px solid black',
    },
  },
  registerForm: {
    display: 'flex',
    width: '100%',
    height: '100%',
    maxWidth: 500,
    flexDirection: 'column',
    padding: 20,

    '& .MuiFormControl-root': {
      marginBottom: 10,
    },
  },
  submitButton: {
    borderRadius: 0,
    backgroundColor: 'black',
    border: '1px solid black',
    color: 'white',
    boxShadow: 'none',
    marginTop: 20,
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'black',
      boxShadow: 'none',
    },
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      fontSize: 14,
      fontWeight: 300,
    },

    '& .MuiSvgIcon-root': {
      fill: 'black',
    },
  },
  legalText: {
    fontWeight: 300,
  },
}));
