import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { Formik } from 'formik';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import clsx from 'clsx';
import i18n from 'i18next';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { useParams } from 'react-router-dom';
import OrderService from 'src/services/orders.service';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { BillingAddress, ReduxStateType } from '../../../store/initialState';
import { BlackOutlinedButton } from '../../../components/atoms/Buttons/BlackOutlinedButton';
import { useOrderDraft } from '../../../hooks/useOrderDraft';
import { setFrames, userActions } from '../../../actions/user.actions';
import { BikeType } from '../../../types/bike.types';
import {
  IPrice,
  SupportedServices,
  SupportedServiceType,
} from '../../../types/record.types';
import { countries } from '../../../utils';
import PageLayout from '../../../templates/pageLayout/pageLayout';
import FrameService from '../../../services/frame.service';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { updateLocalUser } from '../../../services/authentication/userUpdate.service';
import { WeekSchedule } from './components/weekSchedule';
import { useStyles } from './basket.styles';
import { OrderItemFeType, ServiceGroupType } from './basket.model';
import TimeUtils from './utils/timeUtils';
import PriceUtils from './utils/priceUtils';
import { sendBarionPixelEvent } from './utils/barionPixelEvents';

interface IServerServiceType {
  Price: IPrice[];
  _id: string;
  ServiceType: string;
}

const Basket = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const dispatch = useDispatch();
  const userBikes = useSelector(
    (state: ReduxStateType) => state.userInfo?.Frames,
  );
  const [bikes, setBikes] = useState<BikeType[] | null>(userBikes || null);
  const [currentBike, setCurrentBike] = useState<BikeType | null>(
    userBikes?.[0] || null,
  );
  const [supportedServiceForBike, setSupportedServiceForBike] = useState<
    SupportedServiceType[]
  >([]);
  const [
    selectedService,
    setSelectedService,
  ] = useState<SupportedServiceType | null>(null);
  const [
    selectedServiceGroup,
    setSelectedServiceGroup,
  ] = useState<ServiceGroupType | null>(null);
  const [availableServiceFrom, setAvailableServiceFroms] = useState<any>([]);
  const [availableServiceTo, setAvailableServiceTo] = useState<any>([]);
  const [orderItems, setOrderItems] = useState<OrderItemFeType[]>([]);
  const [ServiceFromDate, setServiceFromDate] = useState<Date | string>('');
  const [ServiceFrom, setServiceFrom] = useState<Date | string>('');
  const [ServiceTo, setServiceTo] = useState<Date | string>('');
  const [ServiceToDate, setServiceToDate] = useState<Date | string>('');
  const [availableDropOffTimes, setAvailableDropOffTimes] = useState<string[]>(
    [],
  );
  const [availablePickUpTimes, setAvailablePickUpTimes] = useState<string[]>(
    [],
  );
  const [orderPrice, setOrderPrice] = useState(0);
  const { PaymentId } = useParams<{ PaymentId: string }>();
  const [serviceGroups, setServiceGroups] = useState<ServiceGroupType[]>([]);
  const billingAddress = useRef<HTMLSpanElement>(null);
  const [supportedServices, setSupportedServices] = useState<
    SupportedServiceType[]
  >([]);
  const [orderCurrency, setOrderCurrency] = useState(
    i18n.language === 'cs' ? 0 : 1,
  );
  const { displaySnackbar } = useSnackbar();

  const getInitialData = async () => {
    let pairedSupportedServices: Array<SupportedServiceType>;
    await axios
      .get(`/api/serviceTypes/`)
      .then((response: AxiosResponse<IServerServiceType[]>) => {
        pairedSupportedServices = response.data.map(
          (serviceType: IServerServiceType) => {
            const service = Object.values(SupportedServices).find(
              (type) => serviceType.ServiceType === type.Type,
            );
            if (service !== undefined) {
              return { ...service, Price: serviceType.Price };
            }
          },
        ) as SupportedServiceType[];
        setSupportedServices(pairedSupportedServices);
      })
      .then(() => {
        if (userBikes === undefined) {
          FrameService.getBikes(
            user?.Email,
            (Frames) => {
              setBikes(Frames);
              setCurrentBike(Frames[0]);
              dispatch(setFrames(Frames));
              const availableServices = getServiceBikes(
                Frames[0],
                pairedSupportedServices,
              );
              setSupportedServiceForBike(availableServices);
            },
            (errorMessage) => {
              displaySnackbar('error', errorMessage);
            },
          );
        } else {
          setBikes(userBikes);
          setCurrentBike(userBikes[0]);
          const availableServices = getServiceBikes(userBikes[0]);
          setSupportedServiceForBike(availableServices);
        }
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    if (PaymentId) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/orders/checkPaymentStatus`,
          {
            PaymentId,
          },
        )
        .then((response) => {
          console.log(response);
        });
    }
  }, [PaymentId]);

  const getServiceBikes = (
    bike?: BikeType | undefined,
    availableServices?: SupportedServiceType[],
  ): SupportedServiceType[] => {
    const result = [];
    const frame = bike || currentBike;
    const services = availableServices || supportedServices;

    if (frame && frame.Records?.length === 0 && services.length > 0) {
      const buildService = services.find(
        (service) => service.FEType === SupportedServices.Build.FEType,
      );
      if (buildService !== undefined) {
        result.push(buildService);
      }
    }
    if (
      frame &&
      moment().subtract(3, 'M').isBefore(moment(frame.Registration))
    ) {
      const warrantySponsoredService = services.find(
        (service) =>
          service.FEType === SupportedServices.WarrantySponsored.FEType,
      );
      if (warrantySponsoredService !== undefined) {
        result.push(warrantySponsoredService);
      }
    } else {
      const warrantyRegularService = services.find(
        (service) =>
          service.FEType === SupportedServices.WarrantyRegular.FEType,
      );
      if (warrantyRegularService !== undefined) {
        result.push(warrantyRegularService);
      }
      result.push();
    }
    const annualService = services.find(
      (service) => service.FEType === SupportedServices.Annual.FEType,
    );
    if (annualService !== undefined) {
      result.push(annualService);
    }
    const diagnosticsService = services.find(
      (service) => service.FEType === SupportedServices.Diagnostics.FEType,
    );
    if (diagnosticsService !== undefined) {
      result.push(diagnosticsService);
    }
    return result;
  };

  useEffect(() => {
    if (currentBike) {
      const availableServices = getServiceBikes();
      setSupportedServiceForBike(availableServices);
      setSelectedService(availableServices[0]);
    }
  }, [currentBike, supportedServices]);

  useEffect(() => {
    if (selectedServiceGroup && ServiceFromDate) {
      const newTimes = TimeUtils.getTimeSlots(
        new Date(ServiceFromDate).getDay(),
        selectedServiceGroup,
      );
      setAvailableDropOffTimes(newTimes);
    }
  }, [ServiceFromDate]);

  useEffect(() => {
    let newTimes: string[] = [];
    if (selectedServiceGroup && ServiceToDate) {
      if (ServiceFrom && ServiceFromDate && ServiceToDate) {
        const pickupDate = new Date(ServiceToDate);
        newTimes = TimeUtils.getTimeSlots(
          pickupDate.getDay(),
          selectedServiceGroup,
          moment(pickupDate),
        );
      } else if (ServiceFrom) {
        newTimes = TimeUtils.getTimeSlots(
          new Date(ServiceToDate).getDay(),
          selectedServiceGroup,
        );
      } else {
        newTimes = TimeUtils.getTimeSlots(
          new Date(ServiceToDate).getDay(),
          selectedServiceGroup,
        );
      }
      setAvailablePickUpTimes([...newTimes]);
    }
  }, [ServiceToDate]);

  const changeOrderCurrency = (currencyIndex: number) => {
    const updatedOrderItems = orderItems.map((item) => ({
      ...item,
      UnitPrice: item.RequestedServiceType.Price[currencyIndex].Amount,
    }));
    const updatedOrderPrice = updatedOrderItems.reduce(
      (accumulator, currentValue) => accumulator + currentValue.UnitPrice,
      0,
    );
    setOrderItems(updatedOrderItems);
    setOrderPrice(updatedOrderPrice);
  };

  const handleSelectCurrentBike = (
    e: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const currentFrame = bikes?.find(
      (bike) => bike.FrameNumber === e.target.value,
    );
    if (currentFrame) {
      setCurrentBike(currentFrame);
    }
  };

  const createOrderItem = async () => {
    const itemPrice = selectedService?.Price[orderCurrency].Amount;

    if (currentBike && selectedService) {
      setOrderItems([
        ...orderItems,
        {
          Bike: {
            ...currentBike,
            SKU: {
              ...currentBike.SKU,
              Name:
                currentBike.SKU?.Name.replace('"', '') ??
                currentBike.FrameNumber,
            },
          },
          RequestedServiceType: selectedService,
          ItemTotal:
            itemPrice !== undefined
              ? itemPrice
              : selectedService.Price[0].Amount,
          Quantity: 1,
          Description: selectedService.Type,
          UnitPrice:
            itemPrice !== undefined
              ? itemPrice
              : selectedService.Price[0].Amount,
          Unit: 'pcs',
        },
      ]);
      const updatedOrderPrice = orderItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.UnitPrice,
        selectedService.Price[orderCurrency].Amount,
      );
      setOrderPrice(updatedOrderPrice);
      bikes?.splice(bikes?.indexOf(currentBike), 1);
      setSelectedService(null);
      if (bikes) {
        setBikes(bikes);
        setCurrentBike(bikes[0]);
      }
    }
    if (serviceGroups === undefined || serviceGroups.length === 0) {
      await axios.get('/api/serviceGroups').then((response) => {
        setServiceGroups(response.data);
      });
    }
  };

  const updateOrderPrice = (updatedOrderItems: OrderItemFeType[]) => {
    if (selectedService) {
      return updatedOrderItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.UnitPrice,
        selectedService?.Price[orderCurrency].Amount,
      );
    } else {
      return updatedOrderItems.reduce(
        (accumulator, currentValue) => accumulator + currentValue.UnitPrice,
        0,
      );
    }
  };

  const removeOrderItem = (index: number) => {
    if (orderItems.length === 1) {
      setOrderItems([]);
      if (bikes) {
        setBikes([...bikes, orderItems[index].Bike]);
      } else {
        setBikes([...[], orderItems[index].Bike]);
      }
      setOrderPrice(0);
      setSelectedService(supportedServiceForBike[0]);
      setCurrentBike(orderItems[index].Bike);
    } else {
      if (bikes) {
        setBikes([...bikes, orderItems[index].Bike]);
      }
      setCurrentBike(orderItems[index].Bike);
      orderItems.splice(index, 1);
      setOrderItems([...orderItems]);
      const updatedOrderPrice = updateOrderPrice(orderItems);
      setOrderPrice(updatedOrderPrice);
      setSelectedService(supportedServiceForBike[0]);
    }
  };

  const handleSelectedServiceType = (
    serviceType: ChangeEvent<{ name: string; value: string }>,
  ) => {
    const service = supportedServices.find(
      (service) => service.FEType === serviceType.target.value,
    );
    if (service) {
      setSelectedService(service);
    }
  };

  const handleMarkerClick = (service: ServiceGroupType) => {
    setSelectedServiceGroup(service);
    const availableDropOffDates = TimeUtils.getAvailableDates(service);
    setServiceToDate('');
    setServiceTo('');
    setServiceFrom('');
    setServiceFromDate('');
    setAvailableServiceFroms([...availableDropOffDates]);
    if (
      service.DefaultCurrency !==
      orderItems[0].RequestedServiceType.Price[orderCurrency].Currency
    ) {
      const newCurrency = service.DefaultCurrency === 'CZK' ? 0 : 1;
      changeOrderCurrency(newCurrency);
      setOrderCurrency(newCurrency);
    }
  };

  useEffect(() => {
    updateOrderPrice(orderItems);
  }, [orderCurrency]);

  const handleSelectDate = (date: Date, type: 'Pickup' | 'DropOff') => {
    if (type === 'Pickup') {
      setServiceToDate(date);
    } else {
      if (ServiceTo) {
        setServiceTo('');
      }
      if (ServiceToDate) {
        setServiceToDate('');
      }
      if (ServiceFrom) {
        setServiceFrom('');
      }
      setServiceFromDate(date);
    }
  };

  const handleSelectTime = (
    time: string | Date,
    type: 'Pickup' | 'DropOff',
  ) => {
    const fullDate = type === 'Pickup' ? ServiceToDate : ServiceFromDate;

    if (fullDate) {
      // fullDate = Tue Dec 12 2017 11:18:30 GMT+0530 (IST) {}
      let finalDate: Date;
      if (typeof time === 'string') {
        const hours = parseInt(time.split(':')[0]);
        const minutes = parseInt(time.split(':')[1]);
        finalDate = new Date(new Date(fullDate).setHours(hours));
        finalDate = new Date(finalDate.setMinutes(minutes));
      } else {
        finalDate = new Date(time);
      }
      if (type === 'Pickup') {
        setServiceTo(finalDate);
      } else {
        setServiceFrom(finalDate);
        const longestService = Math.max(
          ...orderItems.map(
            (item) =>
              selectedServiceGroup?.SupportedServiceType.find(
                (serviceType) =>
                  serviceType.ServiceType.ServiceType ===
                  item.RequestedServiceType.Type,
              )?.Duration as number,
          ),
          0,
        );
        const availableDates = TimeUtils.getPickupDates(
          finalDate,
          selectedServiceGroup,
          longestService,
        );
        if (availableDates !== undefined) {
          setAvailableServiceTo([...availableDates]);
        }
      }
    }
  };

  useEffect(() => {
    if (billingAddress !== null && billingAddress.current !== null) {
      window.scrollTo({
        top: billingAddress.current?.getBoundingClientRect().top,
        behavior: 'smooth',
      });
    }
  }, [ServiceTo]);

  const submitOrderDraft = async (BillingAddress: BillingAddress) => {
    if (
      user &&
      ServiceTo &&
      ServiceFrom &&
      selectedServiceGroup &&
      BillingAddress.Country !== null
    ) {
      dispatch(userActions.updateUser({ LastBillingAddress: BillingAddress }));
      updateLocalUser({ LastBillingAddress: BillingAddress });
      sendBarionPixelEvent(
        orderItems,
        selectedServiceGroup.DefaultCurrency,
        orderPrice,
      );
      await OrderService.createOrder(
        {
          Email: user.Email,
          FullName: `${user.FirstName} ${user.LastName}`,
          OrderItems: orderItems,
          Locale: i18n.language,
          OrderPrice: {
            Price: orderPrice,
            Currency: selectedServiceGroup.DefaultCurrency,
          },
          Phone: user.Phone,
          Address: {
            ...BillingAddress,
            Country: BillingAddress.Country.code,
          },
          UserId: user._id,
          ServiceFrom: (ServiceFrom as Date).toISOString(),
          ServiceTo: (ServiceTo as Date).toISOString(),
          ServiceGroup: selectedServiceGroup,
        },
        () => {
          window.location.reload();
        },
        () => {
          window.location.reload();
        },
      );
    }
  };

  const getTimeToRender = (date: string | Date) => {
    const dateToRender = new Date(date);
    return (
      ('0' + dateToRender.getHours()).slice(-2) +
      ':' +
      ('0' + dateToRender.getMinutes()).slice(-2)
    );
  };

  const getUserCountry = (
    userDbCountry: string | { code: string; label: string; phone: string },
  ) => {
    let userCountry;
    if (typeof userDbCountry !== 'string') {
      userCountry = countries.find(
        (country) => country.code === userDbCountry.code,
      );
    } else {
      userCountry = countries.find((country) => country.code === userDbCountry);
    }
    if (userCountry !== undefined) {
      return userCountry;
    } else {
      return null;
    }
  };

  return (
    <PageLayout>
      {bikes && supportedServices.length !== 0 && (
        <Formik
          initialValues={{
            OrderItems: [],
            BillingAddress: user?.LastBillingAddress
              ? {
                  ...user.LastBillingAddress,
                  Country: getUserCountry(
                    (user.LastBillingAddress?.Country as unknown) as string,
                  ),
                }
              : {
                  Street: '',
                  StreetNumber: '',
                  ZIPCode: '',
                  Country: null,
                },
          }}
          onSubmit={(values: {
            OrderItems: OrderItemFeType[];
            BillingAddress: BillingAddress;
          }) => {
            dispatch(
              userActions.updateUser({
                LastBillingAddress: {
                  ...values.BillingAddress,
                },
              }),
            );
            useOrderDraft({
              OrderItems: orderItems,
              BillingAddress: values.BillingAddress,
            });
            submitOrderDraft(values.BillingAddress).then((res) =>
              console.log(res),
            );
          }}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit}>
              {bikes && supportedServices && bikes.length > 0 && (
                <Box
                  className={clsx(classes.basketSectionPanel, 'itemSelection')}
                >
                  <FormControl
                    fullWidth
                    className={classes.frameSelectionSectionFormControl}
                  >
                    <InputLabel>
                      {t('basket_form_input_placeholder_FrameNumber')}
                    </InputLabel>
                    <Select
                      name="orderItem.FrameNumber"
                      id="orderItem.FrameNumber"
                      onChange={handleSelectCurrentBike}
                      className={classes.frameSelectionSection}
                      IconComponent={ExpandMoreRoundedIcon}
                      variant="outlined"
                      value={currentBike?.FrameNumber || bikes[0].FrameNumber}
                      renderValue={() =>
                        currentBike?.SKU?.Name || currentBike?.FrameNumber || ''
                      }
                    >
                      {bikes &&
                        bikes.map((frame) => {
                          if (frame.FrameNumber.length > 0) {
                            return (
                              <MenuItem
                                key={frame.FrameNumber}
                                value={frame.FrameNumber}
                              >
                                {frame.SKU?.Name || frame.FrameNumber}
                              </MenuItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                    </Select>
                  </FormControl>
                  {supportedServiceForBike &&
                    supportedServiceForBike.length > 0 && (
                      <FormControl
                        fullWidth
                        className={classes.frameSelectionSectionFormControl}
                      >
                        <InputLabel>
                          {t('basket_form_input_placeholder_ServiceTask')}
                        </InputLabel>
                        <Select
                          label="Vyberte servisní úkon"
                          name="orderItem.RequestedServiceType"
                          id="orderItem.RequestedServiceType"
                          onChange={(
                            e: ChangeEvent<{ name: string; value: string }>,
                          ) => handleSelectedServiceType(e)}
                          className={classes.frameSelectionSection}
                          variant="outlined"
                          value={
                            selectedService
                              ? selectedService.FEType
                              : supportedServiceForBike[0].FEType
                          }
                        >
                          {supportedServiceForBike &&
                            supportedServiceForBike.map((service) => (
                              <MenuItem
                                key={service.Type}
                                value={service.FEType}
                              >
                                {t(service.Translation)}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    )}
                  <Box className={classes.priceSection}>
                    {selectedService && (
                      <>
                        <Typography>
                          {PriceUtils.formatPrice(
                            selectedService.Price[orderCurrency].Amount,
                            selectedService.Price[orderCurrency].Currency,
                          )}
                        </Typography>
                        <IconButton onClick={createOrderItem}>
                          <AddCircleRoundedIcon />
                        </IconButton>
                      </>
                    )}
                  </Box>
                </Box>
              )}

              {orderItems.length > 0 && (
                <>
                  <Box
                    className={clsx(classes.basketSectionPanel, 'orderItems')}
                  >
                    {orderItems.map((orderItem, index) => (
                      <Box
                        className={classes.basketSectionRow}
                        key={`orderItem${orderItem.Bike.FrameNumber}`}
                      >
                        <Typography
                          variant={'button'}
                          className={classes.orderFrameNumber}
                        >
                          {orderItem.Bike.SKU.Name ||
                            orderItem.Bike.FrameNumber}
                        </Typography>
                        <Box className={classes.orderItemsSelection}>
                          <Typography>
                            {t(orderItem.RequestedServiceType.Translation)}
                          </Typography>
                        </Box>
                        <Box className={classes.priceSection}>
                          <Typography>
                            {PriceUtils.formatPrice(
                              orderItem.RequestedServiceType.Price[
                                orderCurrency
                              ].Amount,
                              orderItem.RequestedServiceType.Price[
                                orderCurrency
                              ].Currency,
                            )}
                          </Typography>
                          <IconButton onClick={() => removeOrderItem(index)}>
                            <RemoveCircleRoundedIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                  {orderPrice && (
                    <Box
                      className={clsx(classes.basketSectionPanel, 'orderItems')}
                    >
                      <Box className={clsx(classes.basketSectionRow, 'sum')}>
                        <Typography>{t('basket_totalPrice')}</Typography>
                        <Box className={classes.priceSection}>
                          <Typography
                            variant={'body1'}
                            className={'totalPrice'}
                          >
                            {PriceUtils.formatPrice(
                              orderPrice,
                              selectedServiceGroup?.DefaultCurrency,
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  {serviceGroups && serviceGroups?.length > 1 && (
                    <Box className={clsx(classes.basketSectionPanel, 'map')}>
                      <LoadScript
                        googleMapsApiKey={
                          'AIzaSyCTYLonORLe2_gt3MfThy50JvhPkMU8Q98'
                        }
                      >
                        <GoogleMap
                          mapContainerClassName={classes.map}
                          center={
                            selectedServiceGroup?.Gps || serviceGroups[0].Gps
                          }
                          zoom={6}
                        >
                          {serviceGroups
                            .filter((serviceGroup) => serviceGroup.IsEnabled)
                            .map((serviceGroup) => (
                              <Marker
                                position={serviceGroup.Gps}
                                key={serviceGroup.id}
                                onClick={() => handleMarkerClick(serviceGroup)}
                              />
                            ))}
                        </GoogleMap>
                      </LoadScript>
                      <Box className={classes.selectedServiceGroup}>
                        {!selectedServiceGroup && (
                          <Box className={clsx(classes.serviceInfo, 'Empty')}>
                            <Typography variant={'h5'} align={'center'}>
                              {t('basket_map_pickService')}
                            </Typography>
                          </Box>
                        )}
                        {selectedServiceGroup && (
                          <>
                            <Box className={classes.serviceInfo}>
                              <Typography variant={'h6'}>
                                {selectedServiceGroup.Name}
                              </Typography>
                              <Typography variant={'subtitle1'}>
                                {selectedServiceGroup.Address}
                              </Typography>
                              <WeekSchedule
                                dutyHours={selectedServiceGroup.Duty}
                              />
                            </Box>
                            {availableServiceFrom && (
                              <Box>
                                <Box className={classes.dateSelection}>
                                  <Typography
                                    variant="body2"
                                    style={{ width: '100%' }}
                                  >
                                    {t('basket_form_legend_chooseDropOffDate')}
                                  </Typography>
                                  <FormControl fullWidth defaultValue={''}>
                                    <InputLabel id={'selectDropOffDate'}>
                                      {t('basket_form_input_label_DropOffDate')}
                                    </InputLabel>
                                    <Select
                                      labelId={'selectDropOffDate'}
                                      name="ServiceFrom"
                                      onChange={(
                                        e: ChangeEvent<{
                                          name: string;
                                          value: Date;
                                        }>,
                                      ) =>
                                        handleSelectDate(
                                          e.target.value,
                                          'DropOff',
                                        )
                                      }
                                      IconComponent={ExpandMoreRoundedIcon}
                                      variant="outlined"
                                      className={classes.frameSelectionSection}
                                      placeholder={t(
                                        'basket_form_input_label_DropOffDate',
                                      )}
                                      defaultValue=""
                                      renderValue={() =>
                                        ServiceFromDate
                                          ? new Date(
                                              ServiceFromDate,
                                            ).toLocaleDateString(
                                              i18n.language,
                                              {
                                                weekday: 'short',
                                                day: 'numeric',
                                                month: 'numeric',
                                              },
                                            )
                                          : t(
                                              'basket_form_input_label_DropOffDate',
                                            )
                                      }
                                    >
                                      {availableServiceFrom.map(
                                        (date: Date) => {
                                          return (
                                            <MenuItem
                                              key={`dropOffDate-${date.toLocaleDateString()}`}
                                              value={date.getTime() || ''}
                                            >
                                              {date.toLocaleDateString(
                                                i18n.language,
                                                {
                                                  weekday: 'short',
                                                  day: 'numeric',
                                                  month: 'numeric',
                                                },
                                              )}
                                            </MenuItem>
                                          );
                                        },
                                      )}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth defaultValue={''}>
                                    <InputLabel>
                                      {t('basket_form_input_label_DropOffTime')}
                                    </InputLabel>
                                    <Select
                                      disabled={
                                        availableDropOffTimes === null ||
                                        availableDropOffTimes.length === 0
                                      }
                                      name="DropOffTime"
                                      onChange={(
                                        e: ChangeEvent<{
                                          name: string;
                                          value: string;
                                        }>,
                                      ) =>
                                        handleSelectTime(
                                          e.target.value,
                                          'DropOff',
                                        )
                                      }
                                      IconComponent={ExpandMoreRoundedIcon}
                                      variant="outlined"
                                      className={classes.frameSelectionSection}
                                      renderValue={() =>
                                        ServiceFrom
                                          ? `${getTimeToRender(ServiceFrom)}`
                                          : t(
                                              'basket_form_input_label_DropOffTime',
                                            )
                                      }
                                    >
                                      {bikes &&
                                        availableDropOffTimes &&
                                        availableDropOffTimes.map(
                                          (time: string) => (
                                            <MenuItem key={time} value={time}>
                                              {time}
                                            </MenuItem>
                                          ),
                                        )}
                                    </Select>
                                  </FormControl>
                                </Box>

                                <Box className={classes.dateSelection}>
                                  <Typography variant="body2">
                                    {t('basket_form_legend_choosePickUpDate')}
                                  </Typography>
                                  <FormControl fullWidth>
                                    <InputLabel>
                                      {t('basket_form_input_label_PickUpDate')}
                                    </InputLabel>
                                    <Select
                                      name="ServiceTo"
                                      onChange={(
                                        e: ChangeEvent<{
                                          name: string;
                                          value: Date;
                                        }>,
                                      ) =>
                                        handleSelectDate(
                                          e.target.value,
                                          'Pickup',
                                        )
                                      }
                                      disabled={!ServiceFrom}
                                      IconComponent={ExpandMoreRoundedIcon}
                                      variant="outlined"
                                      defaultValue=""
                                      renderValue={() =>
                                        ServiceToDate
                                          ? new Date(
                                              ServiceToDate,
                                            ).toLocaleDateString(
                                              i18n.language,
                                              {
                                                weekday: 'short',
                                                day: 'numeric',
                                                month: 'numeric',
                                              },
                                            )
                                          : t(
                                              'basket_form_input_label_DropOffDate',
                                            )
                                      }
                                    >
                                      {bikes &&
                                        availableServiceTo.map(
                                          (date: Date, index: number) => {
                                            const dateString = new Date(date);
                                            return (
                                              <MenuItem
                                                key={`${dateString.toLocaleDateString()} - ${index}s`}
                                                value={date.getTime()}
                                              >
                                                {dateString.toLocaleDateString(
                                                  i18n.language,
                                                  {
                                                    weekday: 'short',
                                                    day: 'numeric',
                                                    month: 'numeric',
                                                  },
                                                )}
                                              </MenuItem>
                                            );
                                          },
                                        )}
                                    </Select>
                                  </FormControl>
                                  <FormControl fullWidth>
                                    <InputLabel>
                                      {t('basket_form_input_label_PickUpTime')}
                                    </InputLabel>
                                    <Select
                                      disabled={!ServiceToDate}
                                      name="PickupTime"
                                      onChange={(
                                        e: ChangeEvent<{
                                          name: string;
                                          value: string;
                                        }>,
                                      ) =>
                                        handleSelectTime(
                                          e.target.value,
                                          'Pickup',
                                        )
                                      }
                                      IconComponent={ExpandMoreRoundedIcon}
                                      variant="outlined"
                                      defaultValue=""
                                      renderValue={() =>
                                        ServiceTo
                                          ? `${getTimeToRender(ServiceTo)}`
                                          : t(
                                              'basket_form_input_label_DropOffTime',
                                            )
                                      }
                                    >
                                      {bikes &&
                                        availablePickUpTimes.map(
                                          (time: string) => (
                                            <MenuItem key={time} value={time}>
                                              {time}
                                            </MenuItem>
                                          ),
                                        )}
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Box>
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}

                  {ServiceFrom && ServiceTo && orderItems.length > 0 && (
                    <Box
                      className={clsx(classes.basketSectionPanel)}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <Typography ref={billingAddress}>
                        {t('basket_billingAddress')}
                      </Typography>
                      <TextField
                        fullWidth
                        id="BillingAddress.Street"
                        name="BillingAddress.Street"
                        label={t('basket_form_input_placeholder_Street')}
                        value={props.values.BillingAddress.Street}
                        onChange={props.handleChange}
                        variant="outlined"
                        required
                        className={classes.input}
                      />
                      <TextField
                        fullWidth
                        id="BillingAddress.StreetNumber"
                        name="BillingAddress.StreetNumber"
                        label={t('basket_form_input_placeholder_StreetNumber')}
                        value={props.values.BillingAddress.StreetNumber}
                        onChange={props.handleChange}
                        variant="outlined"
                        required
                        className={classes.input}
                      />
                      <TextField
                        fullWidth
                        id="BillingAddress.ZIPCode"
                        name="BillingAddress.ZIPCode"
                        label={t('basket_form_input_placeholder_ZIPCode')}
                        value={props.values.BillingAddress.ZIPCode}
                        onChange={props.handleChange}
                        variant="outlined"
                        required
                        className={classes.input}
                      />
                      <Autocomplete
                        className={classes.input}
                        id="BillingAddress.Country"
                        options={countries}
                        autoHighlight
                        getOptionLabel={(option) => t(option.code)}
                        renderOption={(option) => <>{t(option.code)}</>}
                        value={props.values.BillingAddress.Country}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t('basket_form_input_label_Country')}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password', // disable autocomplete and autofill
                            }}
                          />
                        )}
                        onChange={(_, value) =>
                          props.setFieldValue('BillingAddress.Country', value)
                        }
                        fullWidth
                      />
                    </Box>
                  )}
                  {ServiceFrom &&
                    ServiceTo &&
                    orderItems.length > 0 &&
                    props.values.BillingAddress.Street.length > 0 &&
                    props.values.BillingAddress.ZIPCode.length > 0 &&
                    props.values.BillingAddress.StreetNumber.length > 0 &&
                    props.values.BillingAddress.Country !== null && (
                      <Box
                        className={clsx(classes.basketSectionPanel, 'payment')}
                      >
                        <Typography>
                          {t('basket_form_submitOrderNote')}
                        </Typography>
                        <BlackOutlinedButton
                          className={classes.submitOrderButton}
                          onClick={() => props.handleSubmit()}
                        >
                          <span>
                            {`Objednat servis ${PriceUtils.formatPrice(
                              orderPrice,
                              selectedServiceGroup?.DefaultCurrency,
                            )}`}
                          </span>
                          <img
                            width={'175px'}
                            alt={'Payment gate strip'}
                            src={`${process.env.PUBLIC_URL}/barion-card-strip-cz.svg`}
                          />
                        </BlackOutlinedButton>
                      </Box>
                    )}
                </>
              )}
            </form>
          )}
        </Formik>
      )}
    </PageLayout>
  );
};

export default Basket;
