import { OrderItemFeType } from '../basket.model';

export interface BarionPixelProductProperties {
  contentType: string;
  currency: string;
  id: string;
  name: string;
  quantity: number;
  unit: string;
  unitPrice: number;
}

declare global {
  interface Window {
    sendBarionCheckoutAction: (content: any) => void;
  }
}

export const sendBarionPixelEvent = (
  orderItems: OrderItemFeType[],
  currency: string,
  totalPrice: number,
) => {
  const contents = orderItems.map((item) => {
    return {
      contentType: 'service',
      currency: currency,
      id: item.RequestedServiceType.Type,
      name: item.RequestedServiceType.FEType,
      quantity: 1,
      unit: 'pc',
      unitPrice: item.UnitPrice,
    };
  });

  const content = {
    contents: contents,
    currency: currency,
    revenue: totalPrice,
    step: 1,
  };

  window.sendBarionCheckoutAction(content);
};

// const contentViewProperties = {
//   contentType: 'Product',
//   currency: 'HUF',
//   id: 'item_42',
//   name: "Hitchhikers' Guide to the Galaxy, hardcover, ISBN: 9780575115347",
//   quantity: 10.0,
//   unit: 'pcs.',
//   unitPrice: 4990.0,
//   brand: 'Gollancz',
//   category: 'books|hardcover|scifi, books|hardcover|comedy',
//   customerValue: 150.0,
//   ean: '9780575115347',
//   imageUrl:
//     'https://images-na.ssl-images-amazon.com/images/I/51MzUz8rQcL._SX305_BO1,204,203,200_.jpg',
//   list: 'ProductPage',
//   variant: 'hardcover',
// };
