import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      width: '49%',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #000',
      boxShadow: 'none',
      borderRadius: 0,
      padding: 10,

      '& span': {
        fontWeight: 700,
      },
    },

    frameInfoPanel: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 20,
      borderRadius: 5,
      gap: 1,
    },
    modalRoot: {
      margin: 'auto',
      maxWidth: 650,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '&:focus': {
        outline: 'none',
      },
    },
    input: {
      marginTop: 20,
      '& input': { backgroundColor: 'white' },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'black',
      },
      '&.Mui-focused': {
        borderBottom: '2px solid black',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: '2px solid black',
      },
    },
    registerForm: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',

      '& .MuiFormControl-root': {
        marginBottom: 10,
      },
    },
    submitButton: {
      borderRadius: 0,
      backgroundColor: 'black',
      border: '1px solid black',
      color: 'white',
      boxShadow: 'none',
      marginTop: 20,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'black',
        boxShadow: 'none',
      },
    },
    checkbox: {
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontWeight: 300,
      },

      '& .MuiSvgIcon-root': {
        fill: 'black',
      },
    },
  }),
);
