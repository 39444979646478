export const GET_CARDS = 'GET_CARDS';
export const SET_NEW_PUB = 'SET_NEW_PUB';

export const bikesConstants = {
  UPDATE_BIKE_RECORDS: 'UPDATE_BIKE_RECORDS',
  UPDATE_BIKE_OWNER: 'UPDATE_BIKE_OWNER',
  SET_ACTIVE_BIKE: 'SET_ACTIVE_BIKE',
};

export const userConstants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  SET_USERNAME_NAVBAR: 'SET_USERNAME_NAVBAR',
  SET_USER_DATA: 'SET_USER_DATA',
  SET_OWNED_BIKES: 'SET_OWNED_BIKES',
  DELETE_USER_DATA: 'DELETE_USER_DATA',
  UPDATE_USER_DATA: 'UPDATE_USER_DATA',

  SET_USER_ORDERS: 'SET_USER_ORDERS',
  UPDATE_USER_ORDERS: 'UPDATE_USER_ORDERS',
};

export const snackbarConstants = {
  SET_ALL_DATA: 'SET_ALL_DATA',
  SET_SHOW: 'SET_SHOW',
  SET_LABEL: 'SET_LABEL',
  SET_TYPE: 'SET_TYPE',
};
