import React, { PropsWithChildren } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  IconButton,
  Paper,
  TextareaAutosize,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { BlackOutlinedButton } from '../../atoms/Buttons/BlackOutlinedButton';
import FrameRecordService from '../../../services/frameRecordService';
import { BikeType } from '../../../types/bike.types';
import { IServiceTaskForDashboard } from '../../../pages/service/serviceDashboard/serviceTaskTable/serviceTaskRow';
import { useStyles } from './createFrameRecordModal.styles';

export interface IFrameRecord {
  _id: string;
  Created: Date | null;
  IsEnable: boolean;
  ServiceRecordType: string;
  ServiceRecordDetail: string;
}

export interface IFrameRecordErrors {
  Created?: string;
  IsEnable: string;
  ServiceRecordType: string;
  ServiceRecordDetail: string;
}

export interface IFrameRecordModalProps {
  FrameRecordType: string;
  FrameRecord?: IFrameRecord;
  Frame: BikeType;
  ServiceTask: IServiceTaskForDashboard;
  IsFinished: boolean;
}

export const CreateFrameRecordModal = ({
  FrameRecordType,
  Frame,
  ServiceTask,
  FrameRecord,
  IsFinished,
}: PropsWithChildren<IFrameRecordModalProps>): JSX.Element => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const initialValues =
    FrameRecord !== null
      ? {
          Created: FrameRecord?.Created,
          ServiceRecordType: FrameRecord?.ServiceRecordType,
          ServiceRecordDetail: FrameRecord?.ServiceRecordDetail,
          IsEnable: FrameRecord?.IsEnable,
        }
      : {
          Created: null,
          ServiceRecordType: FrameRecordType,
          ServiceRecordDetail: '',
          IsEnable: true,
        };

  const ServiceFrom = new Date(ServiceTask.ServiceFrom);
  const ServiceTo = new Date(ServiceTask.ServiceTo);

  return (
    <>
      <Tooltip
        title={t('createFrameRecordModal_Icon_tooltip').toString()}
        aria-label={t('createFrameRecordModal_Icon_tooltip').toString()}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <EditRoundedIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={isModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Paper className={classes.modalRoot}>
          <Typography variant="h6" align="center">
            {t('createFrameRecordModal_title')}
          </Typography>
          <Box className={classes.frameInfoPanel}>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_sku')}
              </Typography>
              <Typography variant={'body2'}>{Frame.SKU?.Name}</Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_serviceFrom')}
              </Typography>
              <Typography variant={'body2'}>
                {`${ServiceFrom.toLocaleDateString()} ${ServiceFrom.getHours()}:${
                  (ServiceFrom.getMinutes() < 10 ? '0' : '') +
                  ServiceFrom.getMinutes()
                }`}
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_frameNumber')}
              </Typography>
              <Typography variant={'body2'}>{Frame.FrameNumber}</Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_serviceTo')}
              </Typography>
              <Typography variant={'body2'}>
                {`${ServiceTo.toLocaleDateString()} ${ServiceTo.getHours()}:${
                  (ServiceTo.getMinutes() < 10 ? '0' : '') +
                  ServiceTo.getMinutes()
                }`}
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_owner')}
              </Typography>
              <Typography
                variant={'body2'}
              >{`Name: ${ServiceTask.Owner.FirstName} ${ServiceTask.Owner.LastName}`}</Typography>
              <Typography variant={'body2'}>
                {`Phone: ${ServiceTask.Owner.Phone}`}
              </Typography>
            </Paper>
            <Paper className={classes.paper}>
              <Typography variant={'caption'}>
                {t('createFrameRecordModal_frameInfo_requestedService')}
              </Typography>
              <Typography variant={'body2'}>
                {t(`serviceType_${FrameRecordType}`)}
              </Typography>
            </Paper>
          </Box>
          <Formik
            initialValues={initialValues}
            onSubmit={(values: IFrameRecord) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const buttonFlag = document?.activeElement?.getAttribute(
                'data-flag',
              );
              if (FrameRecord) {
                FrameRecordService.updateFrameRecord(
                  {
                    RecordId: FrameRecord._id,
                    FrameId: Frame._id,
                    ServiceRecordDetail: values.ServiceRecordDetail,
                    ServiceRecordType: FrameRecordType,
                    IsEnabled: true,
                    ServiceTaskId: ServiceTask.Id,
                  },
                  buttonFlag === 'saveAndSubmit',
                  () => {
                    handleClose();
                    window.location.reload();
                  },
                  () => {
                    console.log('failure');
                  },
                );
              } else {
                FrameRecordService.createFrameRecord(
                  {
                    FrameId: Frame._id,
                    ServiceRecordDetail: values.ServiceRecordDetail,
                    ServiceRecordType: FrameRecordType,
                    IsEnabled: true,
                    ServiceTaskId: ServiceTask.Id,
                  },
                  buttonFlag === 'saveAndSubmit',
                  () => {
                    handleClose();
                    window.location.reload();
                  },
                  () => {
                    console.log('failure');
                  },
                );
              }
            }}
          >
            {(props) => (
              <form
                onSubmit={props.handleSubmit}
                className={classes.registerForm}
                noValidate
              >
                <TextareaAutosize
                  placeholder={t('createFrameRecordModal_input_placeholder')}
                  name="ServiceRecordDetail"
                  onChange={props.handleChange}
                  value={props.values.ServiceRecordDetail}
                  rowsMin={10}
                />
                <Box>
                  <BlackOutlinedButton
                    type={'submit'}
                    dataFlag={'saveAndSubmit'}
                    disabled={IsFinished}
                  >
                    {t('createFrameRecordModal_button_saveAndPrepare')}
                  </BlackOutlinedButton>
                  <BlackOutlinedButton type={'submit'} dataFlag={'save'}>
                    {t('createFrameRecordModal_button_saveAndClose')}
                  </BlackOutlinedButton>
                  <BlackOutlinedButton type={'button'} onClick={handleClose}>
                    {t('createFrameRecordModal_button_close')}
                  </BlackOutlinedButton>
                </Box>
              </form>
            )}
          </Formik>
        </Paper>
      </Modal>
    </>
  );
};

export default CreateFrameRecordModal;
