import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Typography, Link as MuiLink } from '@material-ui/core';

import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import { useLogin } from '../../../hooks/useLogin';
import ForgotPasswordModal from '../../../components/organisms/forgotPasswordModal/forgotPasswordModal';
import { bikeActions } from '../../../actions/bike.actions';
import { FrameNumberPositionPopover } from '../../../components/molecules/frameNumberPositionModal/frameNumberPositionPopoverl';
import { useStyles } from './loginPage.styles';

const LoginPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLAnchorElement | null>(
    null,
  );

  const openFrameNumberPositionPopover = (
    event: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formik = useFormik({
    initialValues: {
      Login: id ?? '',
      Password: '',
    },
    onSubmit: () => {
      onLoginSubmit();
    },
  });

  // manage submit
  const { onLoginSubmit } = useLogin(
    formik.values,
    (redirectUrl: string) => {
      if (id) {
        dispatch(bikeActions.setActiveBike(formik.values.Login));
      }
      formik.isSubmitting = false;
      history.push(redirectUrl);
    },
    (res) => {
      if (res.status === 400) {
        history.push('/verify');
      }
      if (res.status === 404) {
        formik.errors.Login = t(res.data);
      }
      if (res.status === 401) {
        formik.errors.Password = t(res.data);
      }
      formik.setSubmitting(false);
    },
  );

  useEffect(() => {
    if (localStorage.getItem('user') !== null) {
      history.push('/dashboard');
    }
  }, []);

  // FrameNumber popover variables
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <PageLayout>
      <Box className={classes.root}>
        <Box className={classes.headerTextBox}>
          <Typography variant="h6" component="h2" className={classes.header}>
            {t('loginPage_header')}
          </Typography>
          <Typography variant="body2">
            {' '}
            {t('loginPage_newAccount_label')}
          </Typography>
          <Typography variant="body2">
            <Link to="/register">{t('loginPage_newAccount_action')}</Link>
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit} className={classes.registerForm}>
          <TextField
            fullWidth
            id="Login"
            name="Login"
            label={t('loginPage_loginForm_userName_label')}
            value={formik.values.Login}
            onChange={formik.handleChange}
            error={formik.touched.Login && Boolean(formik.errors.Login)}
            helperText={formik.touched.Login && formik.errors.Login}
            variant="filled"
            className={classes.input}
            disabled={id !== undefined}
          />
          <Typography variant="caption">
            <MuiLink
              tabIndex={-1}
              onClick={openFrameNumberPositionPopover}
              className={classes.link}
            >
              {t('loginPage_loginForm_userNameNote_label_action')}
            </MuiLink>{' '}
            {t('loginPage_loginForm_userNameNote_label')}
          </Typography>
          <FrameNumberPositionPopover
            id={popoverId}
            open={open}
            handleClose={handleClose}
            anchorEl={anchorEl}
          />
          <TextField
            fullWidth
            id="Password"
            name="Password"
            label={t('loginPage_loginForm_password_label')}
            value={formik.values.Password}
            onChange={formik.handleChange}
            error={formik.touched.Password && Boolean(formik.errors.Password)}
            helperText={formik.touched.Password && formik.errors.Password}
            variant="filled"
            className={classes.input}
            type="password"
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            className={classes.submitButton}
          >
            {t('loginPage_submitButton_label')}
          </Button>
          <ForgotPasswordModal />
        </form>
        <Box className={classes.footer}>
          <MuiLink
            variant="body1"
            href="/extendedWarrantyConditions_cs.pdf"
            download
          >
            {t('registerPage_Files_ExtendedWarrantyConditions')}
          </MuiLink>
          <MuiLink variant="body1" href="/warrantyTerms_cs.pdf" download>
            {t('registerPage_Files_WarrantyConditions')}
          </MuiLink>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default withTranslation()(LoginPage);
