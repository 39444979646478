import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import { useStyles } from '../login/loginPage.styles';
import { ReduxStateType } from '../../../store/initialState';
import { BlackOutlinedButton } from '../../../components/atoms/Buttons/BlackOutlinedButton';
import { useVerification } from '../../../hooks/useVerification';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { logout } from '../../../actions/user.actions';

const ResendActivationEmailPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const { displaySnackbar } = useSnackbar();

  // FrameNumber popover variables

  const { onRequestNewVerificationEmail } = useVerification(
    {
      UserId: user?._id,
    },
    () => {
      displaySnackbar(
        'success',
        t('resendActivationEmailPage_requestSuccessful'),
      );
      dispatch(logout());
      history.push('/');
    },
    (res: { status: number; data: string }) => {
      if (res.status === 412) {
        displaySnackbar(
          'success',
          t('resendActivationEmailPage_failure_accountAlreadyActive'),
        );
        history.push('/dashboard');
      } else {
        displaySnackbar('error', t('registerPagePage_form_generalError'));
      }
    },
  );

  return (
    <PageLayout>
      {user && (
        <Box className={classes.root}>
          <Typography>{t('resendActivationEmailPage_mainText')}</Typography>
          <BlackOutlinedButton onClick={onRequestNewVerificationEmail}>
            {t('resendActivationEmailPage_button_text')}
          </BlackOutlinedButton>
        </Box>
      )}
    </PageLayout>
  );
};

export default withTranslation()(ResendActivationEmailPage);
