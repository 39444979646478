import React from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { withRouter } from 'react-router';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import { ReduxStateType } from '../../../store/initialState';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';

const useStyles = makeStyles(() => ({
  header: {
    fontWeight: 700,
    fontSize: 40,
    margin: '20px 0 10px 0',
    textAlign: 'center',
    marginBottom: 30,
  },
  recordContainer: {
    background: 'white',
    margin: '10px 0',
  },
}));

export const BikeRecordsOverviewPage = (): JSX.Element => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const records = useSelector(
    (state: ReduxStateType) =>
      state.userInfo?.Frames.find((bike) => bike._id === id)?.Records,
  );
  const history = useHistory();

  return (
    <>
      <PageLayout>
        <Typography variant="h3" className={classes.header}>
          Přehled servisních zakázek
        </Typography>

        {records &&
          records.map((record) => (
            <TableContainer
              key={record.ServiceRecordType}
              className={classes.recordContainer}
            >
              <Table>
                <tbody>
                  <TableRow>
                    <TableCell>Type</TableCell>
                    <TableCell>{record.ServiceRecordType}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Message</TableCell>
                    <TableCell>{record.ServiceRecordDetail}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>
                      {record.Created &&
                        new Date(record.Created).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                </tbody>
              </Table>
            </TableContainer>
          ))}
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={() => history.push('/dashboard')}>
            <NavigateBeforeIcon fontSize="large" fill="black" />
          </IconButton>
          <Typography
            variant="body2"
            onClick={() => history.push('/dashboard')}
          >
            zpět
          </Typography>
        </Box>
      </PageLayout>
    </>
  );
};

export default withRouter(BikeRecordsOverviewPage);
