import { useDispatch } from 'react-redux';
import authenticationSvc from '../services/authentication';
import { setUserData } from '../actions/user.actions';

interface HookType {
  onUpdateSubmit: (Id: string) => Promise<void>;
}

interface FormStateType {
  FirstName?: string;
  LastName?: string;
  Phone?: string;
  Newsletter: Date | null;
  ServiceInfoMail: Date | null;
}

export const useUserUpdate = (
  formState: FormStateType,
  handleSuccess: () => void,
  handleFailure: (loginRes: { status: number; data: string }) => void,
): HookType => {
  const { userUpdate } = authenticationSvc;
  const dispatch = useDispatch();

  const onUpdateSubmit = async (Id: string) => {
    if (Id && formState) {
      const updateRes = await userUpdate({ Id, Form: formState });
      if (updateRes.status === 200) {
        const fullUser = {
          ...updateRes.data.user,
        };
        localStorage.setItem('user', JSON.stringify(fullUser));
        dispatch(setUserData(fullUser));
        handleSuccess();
      } else {
        handleFailure(updateRes);
      }
    }
  };

  return { onUpdateSubmit };
};
