import axios, { AxiosResponse } from 'axios';
import {
  OrderItemFeType,
  ServiceGroupType,
} from '../pages/service/basket/basket.model';
import { IBarionPaymentResponse } from '../../../server/models/BarionPayment';
import { OrderFEType } from '../store/initialState';

interface IOrderData {
  Email: string;
  FullName: string;
  OrderItems: OrderItemFeType[];
  Locale: string;
  OrderPrice: {
    Price: number;
    Currency: string;
  };
  Phone: string;
  Address: {
    Street: string;
    StreetNumber: string;
    ZIPCode: string;
    Country: string;
  };
  UserId: string;
  ServiceFrom: string;
  ServiceTo: string;
  ServiceGroup: ServiceGroupType;
}

const getUserOrders = (
  userId: string,
  handleSuccess: (orders: OrderFEType[]) => void,
  handleFailure: (error: string) => void,
) => {
  axios
    .get('/api/orders/', {
      params: {
        UserId: userId,
      },
    })
    .then((response) => {
      const Orders = response.data;
      const localUser = JSON.parse(localStorage.getItem('user') as string);
      if (localUser) {
        localUser.Orders = [...Orders];
        localStorage.setItem('user', JSON.stringify(localUser));
      }
      handleSuccess(Orders);
    })
    .catch((e) => {
      handleFailure(e.response.data);
    });
};

const createOrder = (
  orderData: IOrderData,
  handleSuccess: () => void,
  handleFailure: () => void,
) => {
  try {
    axios
      .post('/api/orders/create', orderData)
      .then((payGateResponse: AxiosResponse<IBarionPaymentResponse>) => {
        window.location.href = payGateResponse.data.GatewayUrl;
      })
      .then((res: any) => {
        if (res.status === 200) {
          handleSuccess();
        } else {
          handleFailure();
        }
      });
  } catch (e) {
    console.log(e);
  }
};

const retryPayment = async (orderNumber: number) => {
  try {
    const response = await axios.get('/api/orders/retryPayment/', {
      params: {
        OrderNumber: orderNumber.toString(),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
};

const cancelOrder = (
  orderNumber: number,
  handleSuccess: () => void,
  handleFailure: () => void,
) => {
  try {
    axios
      .put('/api/orders/cancelOrder/', {
        OrderNumber: orderNumber.toString(),
      })
      .then((res: any) => {
        if (res.status === 200) {
          const localUser = JSON.parse(localStorage.getItem('user') as string);
          if (localUser) {
            const updatedOrders = localUser.Orders.filter(
              (order: OrderFEType) => order.OrderNumber !== orderNumber,
            );
            localUser.Orders = [...updatedOrders];
            localStorage.setItem('user', JSON.stringify(localUser));
          }
          handleSuccess();
        } else {
          handleFailure();
        }
      });
  } catch (e) {}
};

const OrderService = {
  getUserOrders: getUserOrders,
  createOrder: createOrder,
  retryPayment: retryPayment,
  cancelOrder: cancelOrder,
};

export default OrderService;
