import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../../../hooks/useSnackBar';
import PageLayout from '../../../templates/pageLayout/pageLayout';
import { useVerification } from '../../../hooks/useVerification';

export const UserActivationPage = () => {
  const { UserId, TemporaryToken } = useParams<{
    UserId: string;
    TemporaryToken: string;
  }>();
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { onVerify } = useVerification(
    {
      UserId,
      TemporaryToken,
    },
    () => {
      displaySnackbar('success', t('userActivationPage_ActivationSuccessful'));
      history.push('/dashboard');
    },
    () => {
      displaySnackbar('error', t('registerPagePage_form_failedRegistration'));
    },
  );

  useEffect(() => {
    onVerify();
  }, []);

  return (
    <PageLayout>
      <Typography>Your account is being activated.</Typography>
    </PageLayout>
  );
};
