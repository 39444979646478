import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'absolute',
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      top: 'calc(50vh - 200px)',
      left: 'calc(50vw - 200px)',
    },
    modalRoot: {
      margin: 'auto',
      maxWidth: 400,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',

      '&:focus': {
        outline: 'none',
      },
    },
    input: {
      marginTop: 20,
      '& input': { backgroundColor: 'white' },
      '& .MuiFormLabel-root.Mui-focused': {
        color: 'black',
      },
      '&.Mui-focused': {
        borderBottom: '2px solid black',
      },
      '& .MuiFilledInput-underline:after': {
        borderBottom: '2px solid black',
      },
    },
    registerForm: {
      display: 'flex',
      width: '100%',
      height: '100%',
      maxWidth: 500,
      flexDirection: 'column',
      padding: 20,

      '& .MuiFormControl-root': {
        marginBottom: 10,
      },
    },
    submitButton: {
      borderRadius: 0,
      backgroundColor: 'black',
      border: '1px solid black',
      color: 'white',
      boxShadow: 'none',
      marginTop: 20,
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'black',
        boxShadow: 'none',
      },
    },
    checkbox: {
      '& .MuiFormControlLabel-label': {
        fontSize: 14,
        fontWeight: 300,
      },

      '& .MuiSvgIcon-root': {
        fill: 'black',
      },
    },
  }),
);
