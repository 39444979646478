import { addBike } from '../services/addBike';

interface HookType {
  onSubmit: () => Promise<void>;
}

interface FormStateType {
  UserId: string;
  FrameNumber: string;
  Registration: Date;
}
export const useAddBike = (
  formState: FormStateType,
  handleSuccess: () => void,
  handleFailure: () => void,
): HookType => {
  const onSubmit = async () => {
    const registrationRes = await addBike(formState);
    if (registrationRes.status === 200) {
      handleSuccess();
    } else {
      handleFailure();
    }
  };
  return { onSubmit };
};
