import React from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  TableContainer,
  Tooltip,
  Typography,
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { useSelector } from 'react-redux';
import { ReduxStateType } from '../../../store/initialState';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useUserUpdate } from '../../../hooks/useUpdateUser';
import { BlackOutlinedButton } from '../../atoms/Buttons/BlackOutlinedButton';
import { useStyles } from './userSettingsModal.styles';

export interface EditUserFormType {
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  GdprCheck: boolean;
  Newsletter: boolean;
  ServiceInfoMail: boolean;
}

export interface EditUserFormErrorType {
  FirstName?: string;
  LastName?: string;
  Email?: string;
  Phone?: string;
  GdprCheck?: string;
  Newsletter?: string;
  ServiceInfoMail?: string;
}

export const UserSettingsModal = (): JSX.Element => {
  const classes = useStyles();
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { displaySnackbar } = useSnackbar();

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const validate = (values: EditUserFormType) => {
    const errors: EditUserFormErrorType = {};
    if (!values.FirstName) {
      errors.FirstName = t('registerPagePage_form_input_error_mandatoryField');
    } else if (values.FirstName.length > 15) {
      errors.FirstName = t('registerPagePage_form_input_error_FirstName');
    }

    if (!values.LastName) {
      errors.LastName = t('registerPagePage_form_input_error_mandatoryField');
    } else if (values.LastName.length > 20) {
      errors.LastName = t('registerPagePage_form_input_error_LastName');
    }

    if (values.Phone && (values.Phone.length < 9 || values.Phone.length > 17)) {
      errors.Phone = t('registerPagePage_form_input_error_phone');
    }

    if (values.Phone.length < 9 || values.Phone.length > 17) {
      errors.Phone = t('registerPagePage_form_input_error_phone');
    }

    return errors;
  };

  const initialValues = {
    FirstName: user?.FirstName,
    LastName: user?.LastName,
    Email: user?.Email,
    Phone: user?.Phone,
    GdprCheck: user?.GdprCheck !== null,
    Newsletter: user?.Newsletter !== null,
    ServiceInfoMail: user?.ServiceInfoMail !== null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: async () => {
      try {
        if (user) {
          onUpdateSubmit(user._id);
        }
      } catch (err) {
        displaySnackbar('error', 'registerPagePage_form_generalError');
      }
    },
  });

  const { onUpdateSubmit } = useUserUpdate(
    {
      FirstName: formik.values.FirstName,
      LastName: formik.values.LastName,
      Phone: formik.values.Phone,
      Newsletter: formik.values.Newsletter ? new Date() : null,
      ServiceInfoMail: formik.values.ServiceInfoMail ? new Date() : null,
    },
    () => {
      handleClose();
      displaySnackbar('success', t('userSettingsModal_snackBar_success'));
    },
    () => {
      displaySnackbar('error', t('userSettingsModal_snackBar_failure'));
    },
  );

  const handleClose = () => {
    setIsModalOpen(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formik.resetForm();
  };

  const handleFormReset = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    formik.resetForm();
  };

  return (
    <>
      <Tooltip
        title={t('userSettingsModal_Icon_tooltip').toString()}
        aria-label={t('userSettingsModal_Icon_tooltip').toString()}
      >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleOpen}
        >
          <PersonIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Modal
        open={isModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TableContainer component={Paper} className={classes.modalRoot}>
          <Typography variant="h6" align="center">
            {t('userSettingsModal_heading')}
          </Typography>
          <form
            onSubmit={formik.handleSubmit}
            className={classes.registerForm}
            noValidate
          >
            <TextField
              fullWidth
              id="FirstName"
              name="FirstName"
              label={t('registerPagePage_form_input_placeholder_FirstName')}
              value={formik.values.FirstName}
              onChange={formik.handleChange}
              error={formik.touched.Email && Boolean(formik.errors.FirstName)}
              helperText={formik.touched.Email && formik.errors.FirstName}
              variant="filled"
              className={classes.input}
              required
            />
            <TextField
              fullWidth
              id="LastName"
              name="LastName"
              label={t('registerPagePage_form_input_placeholder_LastName')}
              value={formik.values.LastName}
              onChange={formik.handleChange}
              error={formik.touched.Email && Boolean(formik.errors.LastName)}
              helperText={formik.touched.Email && formik.errors.LastName}
              variant="filled"
              className={classes.input}
              required
            />
            <TextField
              fullWidth
              id="Email"
              name="Email"
              label={t('registerPagePage_form_input_placeholder_Email')}
              value={formik.values.Email}
              onChange={formik.handleChange}
              error={formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={formik.touched.Email && formik.errors.Email}
              variant="filled"
              className={classes.input}
              required
              disabled
            />
            <TextField
              fullWidth
              id="Phone"
              name="Phone"
              label={t('registerPagePage_form_input_placeholder_phone')}
              value={formik.values.Phone}
              onChange={formik.handleChange}
              error={formik.touched.Phone && Boolean(formik.errors.Phone)}
              helperText={formik.touched.Phone && formik.errors.Phone}
              variant="filled"
              className={classes.input}
              required
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.GdprCheck}
                  onChange={formik.handleChange}
                  name="GdprCheck"
                  color="primary"
                  required
                  disabled
                />
              }
              label={t('registerPagePage_form_input_label_GdprCheck')}
              className={classes.checkbox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.ServiceInfoMail}
                  onChange={formik.handleChange}
                  name="ServiceInfoMail"
                  color="primary"
                />
              }
              label={t('registerPagePage_form_input_label_serviceInfoMail')}
              className={classes.checkbox}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.Newsletter}
                  onChange={formik.handleChange}
                  name="Newsletter"
                  color="primary"
                />
              }
              label={t('registerPagePage_form_input_label_newsletter')}
              className={classes.checkbox}
            />
            <BlackOutlinedButton
              color="primary"
              variant="outlined"
              fullWidth
              type="submit"
              className={classes.submitButton}
              disabled={!formik.dirty}
            >
              {t('userSettingsModal_button_submitChanges')}
            </BlackOutlinedButton>
            <BlackOutlinedButton
              color="primary"
              variant="outlined"
              fullWidth
              type="submit"
              className={classes.submitButton}
              disabled={!formik.dirty}
              onClick={handleFormReset}
            >
              {t('userSettingsModal_button_resetForm')}
            </BlackOutlinedButton>
            <BlackOutlinedButton
              color="primary"
              variant="outlined"
              fullWidth
              type="submit"
              className={classes.submitButton}
              onClick={handleClose}
            >
              {t('userSettingsModal_button_closeDialog')}
            </BlackOutlinedButton>
          </form>
        </TableContainer>
      </Modal>
    </>
  );
};

export default UserSettingsModal;
