export const mockServices = [
  {
    id: '123456798',
    Name: 'Test servis 1',
    Address: 'Andelska Hora 236',
    Gps: {
      lat: 50.20819255333314,
      lng: 12.955029751325636,
    },
    Email: 'servis1@sava.com',
    Duty: [
      [],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '11:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
      ],
    ],
    NextAvailableDrop: 48,
    DefaultCurrency: 'EUR',
    IsEnabled: true,
  },
  {
    id: '78974113',
    Name: 'Test servis 2',
    Address: 'Andelska Hora 236',
    Gps: {
      lat: 50.22482814613813,
      lng: 13.40635788777742,
    },
    Email: 'servis1@sava.com',
    Duty: [
      [],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '11:00',
        },
        {
          open: '13:00',
          close: '17:00',
        },
      ],
      [
        {
          open: '09:00',
          close: '12:00',
        },
      ],
    ],
    NextAvailableDrop: 48,
    DefaultCurrency: 'EUR',
    IsEnabled: true,
  },
];

export const daysInWeek = [
  'day_Monday_short',
  'day_Tuesday_short',
  'day_Wednesday_short',
  'day_Thursday_short',
  'day_Friday_short',
  'day_Saturday_short',
  'day_Sunday_short',
];
