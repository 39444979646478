import React, { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReduxStateType } from '../../../store/initialState';
import { userActions } from '../../../actions/user.actions';

export const OrderReview = () => {
  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (user && !isLoaded) {
      setIsLoaded(true);
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/orders/getPaymentStatus`, {
          UserId: user?._id,
        })
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              userActions.updateUser({ Orders: [...response.data.Orders] }),
            );
            history.push('/dashboard');
          }
        });
    }
  }, [user, isLoaded]);

  return <Typography>Checking your payments status...</Typography>;
};

export default OrderReview;
