import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {
  Link as MuiLink,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';

import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import { useRegistration } from '../../../hooks/useRegistration';
import savaTheme from '../../../theme/theme';
import { emailValidation } from '../../../services/authentication/emailValidation.service';
import { frameNumberValidation } from '../../../services/authentication/frameNumberValidation.service';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { ReduxStateType } from '../../../store/initialState';
import { useStyles } from './registerPage.styles';
import { RegisterFormErrorsType, RegisterFormType } from './registerPage.types';

const RegisterPage = () => {
  const classes = useStyles(savaTheme);
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const user = useSelector((state: ReduxStateType) => state.userInfo);

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values: RegisterFormType) => {
    const errors: RegisterFormErrorsType = {};
    if (!values.FrameNumber) {
      errors.FrameNumber = t(
        'registerPagePage_form_input_error_mandatoryField',
      );
    }

    if (!values.FirstName) {
      errors.FirstName = t('registerPagePage_form_input_error_mandatoryField');
    } else if (values.FirstName.length > 15) {
      errors.FirstName = t('registerPagePage_form_input_error_FirstName');
    }

    if (!values.LastName) {
      errors.LastName = t('registerPagePage_form_input_error_mandatoryField');
    } else if (values.LastName.length > 20) {
      errors.LastName = t('registerPagePage_form_input_error_LastName');
    }

    if (!values.Phone) {
      errors.Phone = t('registerPagePage_form_input_error_mandatoryField');
    }

    if (values.Phone && (values.Phone.length < 9 || values.Phone.length > 17)) {
      errors.Phone = t('registerPagePage_form_input_error_phone');
    }

    if (!values.Email) {
      errors.Email = t('registerPagePage_form_input_error_mandatoryField');
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.Email)
    ) {
      errors.Email = t('registerPagePage_form_input_error_invalidEmail');
    }

    if (!values.Password) {
      errors.Password = t('registerPagePage_form_input_error_mandatoryField');
    }

    if (!values.ConfirmedPassword) {
      errors.ConfirmedPassword = t(
        'registerPagePage_form_input_error_mandatoryField',
      );
    }

    if (values.Password !== values.ConfirmedPassword) {
      errors.Password = t('registerPagePage_form_input_error_password');
      errors.ConfirmedPassword = t(
        'registerPagePage_form_input_error_password',
      );
    }

    if (!values.PurchaseDate) {
      errors.PurchaseDate = t(
        'registerPagePage_form_input_error_mandatoryField',
      );
    }

    if (
      values.PurchaseDate &&
      new Date(values.PurchaseDate).valueOf() > Date.now().valueOf()
    ) {
      errors.PurchaseDate = t(
        'registerPagePage_form_input_error_dateOfPurchase',
      );
    }

    if (!values.GdprCheck) {
      errors.GdprCheck = t('registerPagePage_form_input_error_GdprCheck');
    }

    return errors;
  };

  useEffect(() => {
    if (id) {
      frameNumberValidation({ FrameNumber: id.toUpperCase() }).then((res) => {
        if (res.status === 400 && user === null) {
          history.push(`/login/${id.toUpperCase()}`);
        }
        if (
          res.status === 400 &&
          user?.Frames.find((frame) => frame.FrameNumber === id) !== undefined
        ) {
          history.push('/dashboard');
        }
      });
    }
  });

  const formik = useFormik({
    initialValues: {
      FirstName: '',
      LastName: '',
      Email: '',
      Phone: '',
      Password: '',
      ConfirmedPassword: '',
      FrameNumber: id ?? '',
      GdprCheck: false,
      Newsletter: false,
      ServiceInfoMail: false,
      PurchaseDate: new Date(),
    },
    validate,
    onSubmit: async () => {
      try {
        const EmailResponse = await emailValidation({
          Email: formik.values.Email,
        });
        const FrameNumberResponse = await frameNumberValidation({
          FrameNumber: formik.values.FrameNumber,
        });
        if (EmailResponse.status >= 500 && FrameNumberResponse.status >= 500) {
          displaySnackbar('error', t('registerPagePage_form_generalError'));
          return;
        }
        if (EmailResponse.status !== 204) {
          formik.errors.Email = t(
            'registerPagePage_form_input_error_EmailAlreadyTaken',
          );
        }
        if (FrameNumberResponse.status !== 204) {
          formik.errors.FrameNumber = t(
            'registerPagePage_form_input_error_FrameNumber',
          );
        }
        if (
          EmailResponse.status === 204 &&
          FrameNumberResponse.status === 204
        ) {
          onSubmit().then((res) => console.log(res));
        }
      } catch (err) {
        displaySnackbar('error', t('registerPagePage_form_generalError'));
      }
    },
  });

  // manage submit
  const { onSubmit } = useRegistration(
    {
      FirstName: formik.values.FirstName,
      LastName: formik.values.LastName,
      Email: formik.values.Email,
      Password: formik.values.Password,
      FrameNumber: formik.values.FrameNumber.toUpperCase(),
      Phone: formik.values.Phone,
      GdprCheck: new Date(),
      Newsletter: formik.values.Newsletter ? new Date() : null,
      PurchaseDate: new Date(formik.values.PurchaseDate),
      ServiceInfoMail: formik.values.Newsletter ? new Date() : null,
    },
    () => {
      displaySnackbar(
        'success',
        t('registerPagePage_form_successfulRegistration'),
      );
      history.push('/');
    },
    () => {
      displaySnackbar('error', t('registerPagePage_form_failedRegistration'));
    },
  );

  return (
    <PageLayout>
      <Box className={classes.root}>
        <Typography variant="h1" className={classes.header}>
          {t('registerPagePage_header')}
        </Typography>
        <Typography variant="caption">
          {t('registerPagePage_loginRedirect_label')}
          <Link to="/">
            {' '}
            {t('registerPagePage_loginRedirect_label_action')}
          </Link>{' '}
        </Typography>
        <form
          onSubmit={formik.handleSubmit}
          className={classes.registerForm}
          noValidate
        >
          <TextField
            fullWidth
            id="FrameNumber"
            name="FrameNumber"
            label={t('registerPagePage_form_input_placeholder_FrameNumber')}
            value={formik.values.FrameNumber}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.FrameNumber)}
            helperText={formik.touched.Email && formik.errors.FrameNumber}
            variant="filled"
            disabled={id !== undefined}
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="FirstName"
            name="FirstName"
            label={t('registerPagePage_form_input_placeholder_FirstName')}
            value={formik.values.FirstName}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.FirstName)}
            helperText={formik.touched.Email && formik.errors.FirstName}
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="LastName"
            name="LastName"
            label={t('registerPagePage_form_input_placeholder_LastName')}
            value={formik.values.LastName}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.LastName)}
            helperText={formik.touched.Email && formik.errors.LastName}
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="Email"
            name="Email"
            label={t('registerPagePage_form_input_placeholder_Email')}
            value={formik.values.Email}
            onChange={formik.handleChange}
            error={formik.touched.Email && Boolean(formik.errors.Email)}
            helperText={formik.touched.Email && formik.errors.Email}
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="Phone"
            name="Phone"
            label={t('registerPagePage_form_input_placeholder_phone')}
            value={formik.values.Phone}
            onChange={formik.handleChange}
            error={formik.touched.Phone && Boolean(formik.errors.Phone)}
            helperText={formik.touched.Phone && formik.errors.Phone}
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="PurchaseDate"
            name="PurchaseDate"
            label={t('registerPagePage_form_input_placeholder_dateOfPurchase')}
            type="date"
            value={formik.values.PurchaseDate}
            onChange={formik.handleChange}
            error={
              formik.touched.PurchaseDate && Boolean(formik.errors.PurchaseDate)
            }
            helperText={
              formik.touched.PurchaseDate && formik.errors.PurchaseDate
            }
            variant="filled"
            className={classes.input}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
          <TextField
            fullWidth
            id="Password"
            name="Password"
            label={t('registerPagePage_form_input_placeholder_password')}
            value={formik.values.Password}
            onChange={formik.handleChange}
            error={formik.touched.Password && Boolean(formik.errors.Password)}
            helperText={formik.touched.Password && formik.errors.Password}
            type="password"
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="ConfirmedPassword"
            name="ConfirmedPassword"
            label={t('registerPagePage_form_input_placeholder_confirmPassword')}
            value={formik.values.ConfirmedPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.ConfirmedPassword &&
              Boolean(formik.errors.ConfirmedPassword)
            }
            helperText={
              formik.touched.ConfirmedPassword &&
              formik.errors.ConfirmedPassword
            }
            type="password"
            variant="filled"
            className={classes.input}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.GdprCheck}
                onChange={() =>
                  formik.setValues({
                    ...formik.values,
                    GdprCheck: !formik.values.GdprCheck,
                  })
                }
                name="GdprCheck"
                color="primary"
                required
              />
            }
            label={t('registerPagePage_form_input_label_GdprCheck')}
            className={classes.checkbox}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.ServiceInfoMail}
                onChange={() =>
                  formik.setValues({
                    ...formik.values,
                    ServiceInfoMail: !formik.values.ServiceInfoMail,
                  })
                }
                name="ServiceInfoMail"
                color="primary"
              />
            }
            label={t('registerPagePage_form_input_label_serviceInfoMail')}
            className={classes.checkbox}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.Newsletter}
                onChange={() =>
                  formik.setValues({
                    ...formik.values,
                    Newsletter: !formik.values.Newsletter,
                  })
                }
                name="Newsletter"
                color="primary"
              />
            }
            label={t('registerPagePage_form_input_label_newsletter')}
            className={classes.checkbox}
          />
          <MuiLink
            variant="body1"
            href="/extendedWarrantyConditions_cs.pdf"
            download
          >
            {t('registerPage_Files_ExtendedWarrantyConditions')}
          </MuiLink>
          <MuiLink variant="body1" href="/warrantyTerms_cs.pdf" download>
            {t('registerPage_Files_WarrantyConditions')}
          </MuiLink>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            type="submit"
            className={classes.submitButton}
          >
            {t('registerPagePage_form_button_label_submit')}
          </Button>
        </form>
      </Box>
    </PageLayout>
  );
};

export default withTranslation()(RegisterPage);
