import axios from 'axios';
import { logError } from './catch.service';

interface AddBikeType {
  FrameNumber: string;
  UserId: string;
  Registration: Date;
}

export const addBike = async ({
  FrameNumber,
  UserId,
  Registration,
}: AddBikeType) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/addBike`,
      {
        FrameNumber,
        UserId,
        Registration,
      },
    );
    return res;
  } catch (err) {
    return logError(err);
  }
};
