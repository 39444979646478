import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Paper, TableContainer, Typography, Link } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { usePasswordReset } from '../../../hooks/useResetPassword';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { useStyles } from './forgotPassword.styles';

export const ForgotPasswordModal = (): JSX.Element => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { displaySnackbar } = useSnackbar();

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      Email: '',
    },
    onSubmit: () => {
      onPasswordResetRequest().then((res) => console.log(res));
    },
  });

  const { onPasswordResetRequest } = usePasswordReset(
    { Email: formik.values.Email },
    () => {
      displaySnackbar('success', t('forgotPasswordModal_success'));
      handleClose();
      formik.setValues({ Email: '' });
    },
    (res) => {
      if (res.status === 404) {
        displaySnackbar('error', t('forgotPasswordModal_failure_userNotFound'));
      } else {
        displaySnackbar('error', t('forgotPasswordModal_failure_generalError'));
      }
    },
  );

  return (
    <>
      <Typography variant="caption">
        {t('loginPage_loginForm_passwordNote_label')}{' '}
        <Link className={classes.link} onClick={handleOpen}>
          {t('loginPage_loginForm_passwordNote_label_action')}
        </Link>
      </Typography>
      <Modal
        open={isModalOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TableContainer component={Paper} className={classes.modalRoot}>
          <Typography variant="h6" align="center">
            {t('forgotPasswordModal_heading')}
          </Typography>
          <Typography variant="body2" align="justify">
            {t('forgotPasswordModal_text')}
          </Typography>
          <form onSubmit={formik.handleSubmit} className={classes.registerForm}>
            <TextField
              fullWidth
              id="Email"
              name="Email"
              label={t('forgotPasswordModal_input_label')}
              value={formik.values.Email}
              onChange={formik.handleChange}
              error={formik.touched.Email && Boolean(formik.errors.Email)}
              helperText={formik.touched.Email && formik.errors.Email}
              variant="filled"
              className={classes.input}
            />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              className={classes.submitButton}
            >
              {t('forgotPasswordModal_input_button')}
            </Button>
          </form>
        </TableContainer>
      </Modal>
    </>
  );
};

export default ForgotPasswordModal;
