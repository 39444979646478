import axios from 'axios';
import { logError } from '../catch.service';

export const emailValidation = async ({ Email }: any) => {
  let response;
  try {
    response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/validateEmail`,
      {
        Email,
      },
    );
    return response;
  } catch (err) {
    return logError(err);
  }
};
