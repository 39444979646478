import React from 'react';
import {
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import i18n from 'i18next';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 59,
    height: 59,
    width: 59,
    padding: 5,
    borderRadius: '100%',

    '& .MuiButton-label .MuiTypography-button': {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '1.2rem',
      color: '#2f2f2f',
    },
  },
}));

export const LanguageSwitcher = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLanguageChange = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    i18n.changeLanguage(event.currentTarget.dataset.language as string);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getLanguage = () => {
    switch (i18n.language.toLowerCase()) {
      case 'cs-cz':
        return 'CZ';
      case 'en-us':
        return 'EN';
      default:
        return 'CZ';
    }
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.root}
      >
        <Typography variant="button">{getLanguage()}</Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          data-language="en-US"
          onClick={(e) => handleLanguageChange(e)}
        >
          EN
        </MenuItem>
        <MenuItem
          data-language="cs-CZ"
          onClick={(e) => handleLanguageChange(e)}
        >
          CZ
        </MenuItem>
      </Menu>
    </>
  );
};
