import { useDispatch } from 'react-redux';
import authenticationSvc from '../services/authentication';
import { setUserData } from '../actions/user.actions';

export const useVerification = (
  formState: any,
  handleSuccess: any,
  handleFailure: any,
) => {
  const { verifyUser } = authenticationSvc;
  const dispatch = useDispatch();

  const onVerify = async () => {
    const verificationRes = await verifyUser.userVerification(formState);
    if (verificationRes.status === 200) {
      const fullUser = {
        ...verificationRes.data.user,
        token: verificationRes.data.token,
      };
      localStorage.setItem('user', JSON.stringify(fullUser));
      dispatch(setUserData(fullUser));
      handleSuccess();
    } else {
      handleFailure();
    }
  };

  const onRequestNewVerificationEmail = async () => {
    const verificationRes = await verifyUser.resendUserVerificationEmail(
      formState,
    );
    if (verificationRes.status === 200) {
      handleSuccess();
    } else {
      handleFailure();
    }
  };

  return { onVerify, onRequestNewVerificationEmail };
};
