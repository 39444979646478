import authenticationSvc from '../services/authentication';

interface HookType {
  onPasswordResetRequest: () => Promise<void>;
  onPasswordResetSubmit: () => Promise<void>;
  passwordResetGetUser: () => Promise<void>;
}

interface FormStateType {
  Email?: string;
  Password?: string;
  ResetPasswordToken?: string;
}

export const usePasswordReset = (
  formState: FormStateType,
  handleSuccess: (passwordResetResponse: any) => void,
  handleFailure: (passwordResetResponse: any) => void,
): HookType => {
  const { passwordReset } = authenticationSvc;

  const onPasswordResetRequest = async () => {
    const passwordResetResponse = await passwordReset.passwordResetRequest(
      formState,
    );
    if (passwordResetResponse.status === 200) {
      handleSuccess(passwordResetResponse);
    } else {
      handleFailure(passwordResetResponse);
    }
  };

  const onPasswordResetSubmit = async () => {
    const passwordResetResponse = await passwordReset.passwordResetValidate(
      formState,
    );
    if (passwordResetResponse.status === 200) {
      handleSuccess(passwordResetResponse);
    } else {
      handleFailure(passwordResetResponse);
    }
  };

  const passwordResetGetUser = async () => {
    const passwordResetResponse = await passwordReset.passwordResetGetUser(
      formState,
    );
    if (passwordResetResponse.status === 200) {
      handleSuccess(passwordResetResponse);
    } else {
      handleFailure(passwordResetResponse);
    }
  };

  return {
    onPasswordResetRequest,
    onPasswordResetSubmit,
    passwordResetGetUser,
  };
};
