import { IconButton, Tooltip } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { logout } from '../../../actions/user.actions';

export const LogoutButton = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const handleLogout = () => {
    dispatch(logout());
    history.push('/');
    localStorage.removeItem('user');
  };

  return (
    <Tooltip
      title={t('navigation_logout_tooltip').toString()}
      aria-label={t('navigation_logout_tooltip').toString()}
    >
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleLogout}
      >
        <ExitToAppIcon fontSize="large" />
      </IconButton>
    </Tooltip>
  );
};

export default LogoutButton;
