import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import { PageLayout } from '../../../templates/pageLayout/pageLayout';
import savaTheme from '../../../theme/theme';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { usePasswordReset } from '../../../hooks/useResetPassword';
import { useStyles } from './resetPasswordPage.styles';

export interface ResetPasswordFormType {
  Password: string;
  ConfirmedPassword: string;
  ResetPasswordToken: string;
}

export interface ResetPasswordFormErrorsType {
  Password?: string;
  ConfirmedPassword?: string;
  ResetPasswordToken?: string;
}

interface ResetPasswordPageUSerType {
  Email: string;
  Password: string;
  ResetPasswordToken: string;
}

const ResetPasswordPage = () => {
  const classes = useStyles(savaTheme);
  const { ResetPasswordToken } = useParams<{ ResetPasswordToken: string }>();
  const { t } = useTranslation();
  const history = useHistory();
  const { displaySnackbar } = useSnackbar();
  const [user, setUser] = useState<ResetPasswordPageUSerType | null>(null);

  // A custom validation function. This must return an object
  // which keys are symmetrical to our values/initialValues
  const validate = (values: ResetPasswordFormType) => {
    const errors: ResetPasswordFormErrorsType = {};

    if (!values.Password) {
      errors.Password = t('registerPagePage_form_input_error_mandatoryField');
    }

    if (!values.ConfirmedPassword) {
      errors.ConfirmedPassword = t(
        'registerPagePage_form_input_error_mandatoryField',
      );
    }

    if (values.Password !== values.ConfirmedPassword) {
      errors.Password = t('registerPagePage_form_input_error_password');
      errors.ConfirmedPassword = t(
        'registerPagePage_form_input_error_password',
      );
    }

    return errors;
  };

  const { passwordResetGetUser } = usePasswordReset(
    {
      ResetPasswordToken,
    },
    (res) => {
      setUser(res.data.user);
    },
    (res) => {
      if (res.status === 404) {
        displaySnackbar('error', t('resetPasswordPage_failure_userNotFound'));
      } else {
        displaySnackbar('error', t('resetPasswordPage_failure_generalError'));
      }
    },
  );

  useEffect(() => {
    if (user === null) {
      passwordResetGetUser();
    }
  }, [passwordResetGetUser, user]);

  const formik = useFormik({
    initialValues: {
      Password: '',
      ConfirmedPassword: '',
      ResetPasswordToken,
    },
    validate,
    onSubmit: async () => {
      try {
        onPasswordResetSubmit();
      } catch (err) {
        displaySnackbar('error', t('resetPasswordPage_failure_generalError'));
      }
    },
  });

  // manage submit
  const { onPasswordResetSubmit } = usePasswordReset(
    {
      Email: user?.Email,
      Password: formik.values.Password,
      ResetPasswordToken,
    },
    () => {
      displaySnackbar('success', t('resetPasswordPage_validation_success'));
      history.push('/');
    },
    (res) => {
      displaySnackbar('error', t(res.statusText));
    },
  );

  return (
    <PageLayout>
      <Box className={classes.root}>
        <Typography variant="h1" className={classes.header}>
          {t('resetPasswordPage_heading')}
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.registerForm}>
          <TextField
            fullWidth
            id="Password"
            name="Password"
            label={t('registerPagePage_form_input_placeholder_password')}
            value={formik.values.Password}
            onChange={formik.handleChange}
            error={formik.touched.Password && Boolean(formik.errors.Password)}
            helperText={formik.touched.Password && formik.errors.Password}
            type="password"
            variant="filled"
            className={classes.input}
            required
          />
          <TextField
            fullWidth
            id="ConfirmedPassword"
            name="ConfirmedPassword"
            label={t('registerPagePage_form_input_placeholder_confirmPassword')}
            value={formik.values.ConfirmedPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.ConfirmedPassword &&
              Boolean(formik.errors.ConfirmedPassword)
            }
            helperText={
              formik.touched.ConfirmedPassword &&
              formik.errors.ConfirmedPassword
            }
            type="password"
            variant="filled"
            className={classes.input}
            required
          />
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            type="submit"
            className={classes.submitButton}
          >
            {t('resetPasswordPage_button_text')}
          </Button>
        </form>
      </Box>
    </PageLayout>
  );
};

export default withTranslation()(ResetPasswordPage);
