import { snackbarConstants } from '../actions/types';
import { ReduxStateType } from '../store/initialState';

interface Action {
  type: string;
  payload: any;
}

export const snackbarReducer = (
  state = {},
  action: Action,
): Partial<ReduxStateType> => {
  const { payload } = action;
  switch (action.type) {
    case snackbarConstants.SET_ALL_DATA:
      return {
        ...state,
        ...payload,
      };
    case snackbarConstants.SET_SHOW:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
