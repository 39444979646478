import { useTranslation } from 'react-i18next';
import { Link, makeStyles, Popover, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  popover: {
    '& .MuiPopover-paper': {
      padding: 20,
      width: 250,
      height: 250,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
  },
  redirectLink: {
    borderRadius: 0,
    fontWeight: 'bold',
    width: '100%',
    marginTop: 10,
    height: 50,
    backgroundColor: 'transparent',
    border: '1px solid black',
    color: 'black',
    boxShadow: 'none',
    textAlign: 'center',
    padding: 13,
    textTransform: 'uppercase',

    '&:hover': {
      backgroundColor: 'black',
      color: 'white',
      boxShadow: 'none',
      textDecoration: 'none',
    },
  },
}));

export const RedirectPopover = (props: {
  id: string | undefined;
  anchorEl: HTMLButtonElement | null;
  handleClose: () => void;
  open: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      className={classes.popover}
    >
      <Typography align="center" variant="body1">
        {t('navigation_logo_popover_text')}
      </Typography>
      <Link
        href="https://www.sava-bicycles.com"
        className={classes.redirectLink}
        target="_blank"
      >
        {t('navigation_logo_popover_buttonLabel')}
      </Link>
    </Popover>
  );
};
