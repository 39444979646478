import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { GuardedRouteProps } from './guardRouote.data';

const GuardedRoute: React.FC<GuardedRouteProps> = (
  props: GuardedRouteProps,
) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={() =>
        props.authorized ? <Component {...rest} /> : <Redirect to="/" />
      }
    />
  );
};

export default GuardedRoute;
