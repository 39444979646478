import React from 'react';
import Modal from '@material-ui/core/Modal';
import { Paper, Typography } from '@material-ui/core';
import { Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from '../../../hooks/useSnackBar';
import { BlackOutlinedButton } from '../../atoms/Buttons/BlackOutlinedButton';
import { useStyles } from '../createBikeRecordModal/createFrameRecordModal.styles';
import FrameService from '../../../services/frame.service';

export interface ConnectFrameFormType {
  SKU: string;
}

export interface ConnectFrameFormErrorType {
  SKU?: string;
}

export interface ConnectFrameModalProps {
  FrameNumber: string;
}

export const ConnectFrameModal = ({
  FrameNumber,
}: ConnectFrameModalProps): JSX.Element => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { t } = useTranslation();
  const { displaySnackbar } = useSnackbar();

  const handleOpen = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const validate = (values: ConnectFrameFormType) => {
    const errors: ConnectFrameFormErrorType = {};
    if (!values.SKU) {
      errors.SKU = t('connectFrame_Modal_error');
    }
    return errors;
  };

  const initialValues = {
    SKU: '',
  };

  return (
    <>
      <BlackOutlinedButton
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          handleOpen(e)
        }
        style={{ width: '200px', marginTop: '0px' }}
      >
        {t('connectFrameModal_openButton')}
      </BlackOutlinedButton>
      <Modal
        open={isModalOpen}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        onClose={handleClose}
      >
        <Paper className={classes.modalRoot}>
          <Typography variant="h6" align="center">
            {t('connectFrameModal_header')}
          </Typography>
          <Typography>{t('connectFrameModal_text')}</Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              validate(values);
              FrameService.connectFrameToSKU(
                FrameNumber,
                values.SKU,
                () => {
                  setIsModalOpen(false);
                  displaySnackbar('success', t('Rám byl úspěšně spárován'));
                  actions.setSubmitting(false);
                  window.location.reload();
                },
                (message) => {
                  setIsModalOpen(false);
                  displaySnackbar('error', t(message));
                  actions.setSubmitting(false);
                },
              );
            }}
          >
            {(props: FormikProps<ConnectFrameFormType>) => (
              <form
                onSubmit={props.handleSubmit}
                className={classes.registerForm}
                noValidate
              >
                <TextField
                  fullWidth
                  id="SKU"
                  name="SKU"
                  placeholder={t('connectFrameModal_input_placeholder')}
                  value={props.values.SKU}
                  onChange={props.handleChange}
                  error={
                    props.errors.SKU !== undefined &&
                    props.errors.SKU.length > 0
                  }
                  helperText={props.touched.SKU && props.errors.SKU}
                  variant="filled"
                  className={classes.input}
                  required
                />
                <BlackOutlinedButton
                  color="primary"
                  variant="outlined"
                  fullWidth
                  type="button"
                  className={classes.submitButton}
                  onClick={handleClose}
                >
                  {t('connectFrameModal_closeButton')}
                </BlackOutlinedButton>
                <BlackOutlinedButton
                  color="primary"
                  variant="outlined"
                  fullWidth
                  className={classes.submitButton}
                  onClick={() => props.handleSubmit()}
                >
                  {t('connectFrameModal_submitButton')}
                </BlackOutlinedButton>
              </form>
            )}
          </Formik>
        </Paper>
      </Modal>
    </>
  );
};

export default ConnectFrameModal;
