import { userLogin } from './userLogin.service';
import { emailValidation } from './emailValidation.service';
import { userRegistration } from './userRegistration.service';
import { userUpdate } from './userUpdate.service';
import {
  passwordResetGetUser,
  passwordResetRequest,
  passwordResetValidate,
} from './passwordReset.service';
import {
  resendUserVerificationEmail,
  userVerification,
} from './userVerification.service';

export default {
  userRegistration,
  emailValidation,
  passwordReset: {
    passwordResetRequest,
    passwordResetValidate,
    passwordResetGetUser,
  },
  userLogin,
  userUpdate,
  verifyUser: {
    resendUserVerificationEmail,
    userVerification,
  },
};
