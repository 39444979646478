import React, { useState } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import moment from 'moment';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { OrderFEType } from '../../../../store/initialState';
import OrderService from '../../../../services/orders.service';
import { useSnackbar } from '../../../../hooks/useSnackBar';
import { BlackOutlinedButton } from '../../../../components/atoms/Buttons/BlackOutlinedButton';
import { SavaTheme } from '../../../../theme/theme';

interface OrderTableRowProps {
  order: OrderFEType;
}

const useStyles = makeStyles((theme: SavaTheme) => ({
  orderTableRow: {
    '&.orderPaid': {
      backgroundColor: '#ffc800',
      '& .MuiTableCell-body': {
        color: 'white',
      },
    },
    '&.orderFinished': {
      backgroundColor: 'green',
      '& .MuiTableCell-body': {
        color: 'white',
      },
    },

    '& .unpaidOrder': {
      color: 'red',
      fontWeight: theme.typography.fontWeightBold,
    },
  },

  orderActionButton: {
    maxWidth: 250,

    '&:not(:last-of-type)': {
      marginRight: 10,
    },
  },

  orderRowPayActionButton: {
    marginTop: 0,
    '& span': {
      fontSize: 12,
    },
  },
}));

export const OrderTableRow = (props: OrderTableRowProps) => {
  const { order } = props;
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { displaySnackbar } = useSnackbar();

  const isFinished =
    order.OrderStatePaid !== null && order.OrderStateFinished !== null;
  const isPaid =
    order.OrderStatePaid !== null && order.OrderStateFinished === null;

  const classes = useStyles();

  const handleRetryPayment = async () => {
    const response = await OrderService.retryPayment(order.OrderNumber);
    if (response.status === 200) {
      window.location.href = response.data.GatewayUrl;
    } else {
      displaySnackbar(
        'failure',
        t('userDetailPage_orderTable_orderPayment_failure'),
      );
    }
  };

  const handleCancelOrder = () => {
    OrderService.cancelOrder(
      order.OrderNumber,
      () => {
        displaySnackbar(
          'success',
          t('userDetailPage_orderTable__orderCancellation_success'),
        );
        window.location.reload();
      },
      () => {
        displaySnackbar(
          'failure',
          t('userDetailPage_orderTable__orderCancellation_failure'),
        );
      },
    );
  };

  const getOrderStatusText = () => {
    if (!isPaid && !isFinished) {
      return t('userDetailPage_orderTable__status_awaitingPayment');
    } else if (isPaid && !isFinished) {
      return t('userDetailPage_orderTable__status_awaitingService');
    } else if (isFinished) {
      return t('userDetailPage_orderTable__status_ready');
    }
  };

  return (
    <>
      <TableRow
        key={order.OrderNumber}
        className={clsx(
          classes.orderTableRow,
          {
            orderPaid: isPaid,
          },
          {
            orderFinished: isFinished,
          },
          {
            orderUnpaid: !isPaid && !isFinished,
          },
        )}
      >
        <TableCell>
          {typeof order.OrderPrice !== 'string'
            ? `${order.OrderPrice.Price.toFixed(2)}\u00A0${
                order.OrderPrice.Currency
              }`
            : order.OrderPrice}
        </TableCell>
        {order.OrderStatePaid !== null && (
          <TableCell align={'center'}>
            {moment(order.OrderStatePaid).format('DD/MM/YY')}
          </TableCell>
        )}
        {order.OrderStatePaid === null && (
          <TableCell>
            <Tooltip title={'Opakovat platbu'}>
              <BlackOutlinedButton
                className={classes.orderRowPayActionButton}
                onClick={handleRetryPayment}
              >
                {t('userDetailPage_orderTable__action_pay')}
              </BlackOutlinedButton>
            </Tooltip>
          </TableCell>
        )}
        <TableCell
          className={clsx({ unpaidOrder: order.OrderStatePaid === null })}
        >
          {getOrderStatusText()}
        </TableCell>
        <TableCell>{order.ServiceGroup.Address}</TableCell>
        <TableCell>
          {new Date(order.ServiceFrom).toLocaleDateString()}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUpIcon
                htmlColor={isPaid || isFinished ? 'white' : 'black'}
              />
            ) : (
              <KeyboardArrowDownIcon
                htmlColor={isPaid || isFinished ? 'white' : 'black'}
              />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box style={{ padding: '10px 0' }}>
              <Typography variant="h6" gutterBottom component="div">
                {`${t('userDetailPage_orderTable__text_orderDetails')} ${
                  order.OrderNumber
                }`}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align={'left'} colSpan={1}>
                      {t('userDetailPage_orderList_orderCreated')}
                    </TableCell>

                    <TableCell align={'left'} colSpan={3}>
                      {moment(order.OrderStateCreated).format('DD/MM/YY')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align={'left'}>
                      {t(
                        'userDetailPage_orderTable__detail_header_dropOffDate',
                      )}
                    </TableCell>
                    <TableCell align={'left'}>
                      {moment(new Date(order.ServiceFrom)).format(
                        'DD/MM/YY HH:mm',
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ display: 'flex' }}>
                    {order.OrderStatePaid === null && (
                      <BlackOutlinedButton
                        onClick={() => handleRetryPayment()}
                        className={classes.orderActionButton}
                      >
                        {t('userDetailPage_orderTable__action_pay')}
                      </BlackOutlinedButton>
                    )}
                    {!order.OrderStateFinished && (
                      <BlackOutlinedButton
                        onClick={() => handleCancelOrder()}
                        className={classes.orderActionButton}
                      >
                        {t('userDetailPage_orderTable__action_cancel')}
                      </BlackOutlinedButton>
                    )}
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {t(
                        'userDetailPage_orderTable__detail_header_frameNumber',
                      )}
                    </TableCell>

                    <TableCell align="right">
                      {t(
                        'userDetailPage_orderTable__detail_header_serviceType',
                      )}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {order &&
                    order.ServiceTask.length > 0 &&
                    order.ServiceTask.map((task) => (
                      <>
                        {task.Frame && (
                          <TableRow key={task._id}>
                            <TableCell component="th" scope="row">
                              {task.Frame.FrameNumber}
                            </TableCell>

                            <TableCell align="right">
                              {t(`serviceType_${task.RequestedServiceType}`)}
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};
