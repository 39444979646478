import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { Snackbar, ThemeProvider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from '@material-ui/lab';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import i18n from './i18n';
import LoginPage from './pages/service/login/loginPage';
import Register from './pages/service/register/registerPage';
import UserDetailPage from './pages/service/userDetailPage/userDetailPage';
import savaTheme from './theme/theme';
import { setUserData } from './actions/user.actions';
import GuardedRoute from './components/atoms/guardRoute/guardRoute';
import { ReduxStateType } from './store/initialState';
import BikeRecordsOverviewPage from './pages/service/bikeRecordsOverivew/bikeRecordsOverview';
import { UserActivationPage } from './pages/service/userActivationPage/userActivationPage';
import ResetPasswordPage from './pages/service/resetPasswordPage/resetPasswordPage';
import ResendActivationEmailPage from './pages/service/resendActivationEmail/resendActivationEmail';
import Basket from './pages/service/basket/basket';
import OrderReview from './pages/service/orderReview/orderReview';
import ServiceDashboard from './pages/service/serviceDashboard/serviceDashboard';

const App = (): JSX.Element => {
  axios.interceptors.request.use(
    (request) => {
      if (request.url?.includes('api')) {
        const user = localStorage.getItem('user');
        if (user) {
          const bearer = JSON.parse(user).token;
          request.headers['authorization'] = bearer;
          return request;
        }
        return request;
      }
      return request;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.status === 401) {
        localStorage.removeItem('user');
        window.location.href = '/login';
        return response;
      }
      if (response.status === 400) {
        console.log(response);
      }

      return response;
    },
    (error) => {
      if (
        error.response.status === 400 &&
        error.response.message === 'Invalid token'
      ) {
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(error);
      }
      return Promise.reject(error);
    },
  );

  const user = useSelector((state: ReduxStateType) => state.userInfo);
  const dispatch = useDispatch();
  const [snackBarOpen, setSnackBarOpen] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const snackbar = useSelector((state: ReduxStateType) => state.snackbarData);

  useEffect(() => {
    if (user === null) {
      const userInLocalStorage = JSON.parse(
        localStorage.getItem('user') as string,
      );
      if (userInLocalStorage !== null) {
        dispatch(setUserData(userInLocalStorage));
      }
    }
  }, []);

  useEffect(() => {
    if (snackbar.show) {
      setSnackBarOpen({ ...snackBarOpen, open: snackbar.show });
    }
  }, [snackbar]);

  const handleCloseSnackBar = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen({ ...snackBarOpen, open: false });
  };

  return (
    <ThemeProvider theme={savaTheme}>
      <I18nextProvider i18n={i18n}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router>
            <Switch>
              <Route path="/" exact component={LoginPage} />
              <Route
                path="/verify/:UserId/:TemporaryToken"
                exact
                component={UserActivationPage}
              />
              <Route
                path="/verify"
                exact
                component={ResendActivationEmailPage}
              />
              <Route path="/login/:id" exact component={LoginPage} />
              <Route path="/register/:id" exact component={Register} />
              <Route path="/register/" exact component={Register} />
              <Route
                path="/passwordReset/:ResetPasswordToken"
                component={ResetPasswordPage}
              />
              {/* <GuardedRoute authorized={user !== null} path={`/dashboard/:id`} component={BikeDetailPage} /> */}
              <Route
                exact
                path="/dashboard/records/:id"
                component={BikeRecordsOverviewPage}
              />
              <Route path="/basket/success" component={OrderReview} />
              <Route exact path="/basket" component={Basket} />
              <GuardedRoute
                exact
                authorized={user !== null}
                path="/serviceDashboard"
                component={ServiceDashboard}
              />
              <GuardedRoute
                exact
                authorized={user !== null}
                path="/dashboard"
                component={UserDetailPage}
              />
            </Switch>
            <div className="App" />
          </Router>
          <Snackbar
            open={snackBarOpen.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          >
            <Alert onClose={handleCloseSnackBar} severity={snackbar.type}>
              {snackbar.label}
            </Alert>
          </Snackbar>
        </MuiPickersUtilsProvider>
      </I18nextProvider>
    </ThemeProvider>
  );
};

export default App;
