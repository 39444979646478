import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { DutyHours } from '../basket.model';
import { useStyles } from '../basket.styles';
import { daysInWeek } from '../fixtures/basket.data';

interface WeekScheduleProps {
  dutyHours: DutyHours[][];
}

export const WeekSchedule = (props: WeekScheduleProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {props.dutyHours.map((day, index) => (
        <Box key={`${Math.random()}`} className={classes.weekSchedule}>
          <Typography
            variant={'button'}
            className={classes.weekScheduleWeekday}
          >{`${t(daysInWeek[index])}: `}</Typography>
          <Box key={`${day}/${index}`} className={classes.weekScheduleDay}>
            {day.length > 0 &&
              day.map((key, index) => (
                <Box
                  key={`${key}/${index}`}
                  className={classes.weekScheduleDayDuty}
                >
                  <Typography>{`${key.open.toString()} -`}</Typography>
                  <Typography key={`${key}-${key.close}`}>
                    {`${key.close} ${
                      index % 2 === 0 && day.length !== 1 ? ' | ' : ''
                    }`}
                  </Typography>
                </Box>
              ))}
            {day.length === 0 && (
              <Box className={classes.weekScheduleDayDuty}>
                <Typography>Zavřeno</Typography>
              </Box>
            )}
          </Box>
        </Box>
      ))}
    </>
  );
};
