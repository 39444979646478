import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, responsiveFontSizes, Theme } from '@material-ui/core';
import React, { PropsWithChildren } from 'react';

let savaTheme: Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    background: {
      default: 'white',
    },
  },
  typography: {
    fontFamily: ['korolev'].join(','),
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontWeightMedium: 400,
    fontWeightBold: 700,
    fontSize: 16,
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
        '& .MuiFormLabel-root.Mui-focused': {
          color: 'black',
        },
        '&.Mui-focused': {
          borderBottom: '2px solid black',
        },
        '& .MuiFilledInput-underline:after': {
          borderBottom: '2px solid black',
        },
      },
    },
  },
}) as Theme;

export interface SavaTheme extends Theme {
  savaTheme: typeof savaTheme;
}

savaTheme = responsiveFontSizes(savaTheme);

export const MuiTheme = ({ children }: PropsWithChildren<any>) => {
  return (
    <MuiThemeProvider theme={savaTheme}>
      <CssBaseline>{children}</CssBaseline>
    </MuiThemeProvider>
  );
};

export default savaTheme;
